import { ErrorEventModel, Meta, Product } from "types/shared";

export interface PurchaseOrder {
    mongoId: string;
    purchaseOrderNumber: number;
    supplier: Supplier;

    submitted: boolean;

    expectedDeliveryDate: string; // Dateonly
    purchase: PurchaseDetails;
    items: PurchaseItem[];

    price: PurchasePrice;
    errorEvents: ErrorEventModel[];
    isNeedsAttention: boolean;

    meta: Meta;
}

export enum PurchaseOrderMethodEnum {
    None = 0,
    Email = 1,
    Transus = 2,
}

export interface PurchasePrice {
    totalPurchasePrice: {
        value: number;
    }
}

export interface Supplier {
    name: string;
    montaCode: string;
}

export interface PurchaseDetails {
    isManual: boolean;
    method: PurchaseOrderMethodEnum;
    purchaseEmail: string | null;
}

export interface PurchaseItem {
    reference: string | null;
    product: Product;
    price: Price;
    quantity: number;
}

export interface Price {
    purchasePrice: {
        value: number;
    };
}

export interface PurchaseOrderSearchCriteria {
    number: string | null;
    supplierMontaCode: string | null;
    sku: string | null;
    isSubmitted: boolean | null;
    isNeedsAttention: boolean | null;
    isInboundCreated: boolean | null;
    page: number;
}


export enum PurchaseOrderSubmitSelectEnum {
    Any = "",
    Submitted = "true",
    NotSubmitted = "false"
}
