const Snowflake = ({ size, left, animationDuration }: { size: number, left: number, animationDuration: number} ) => {
    const style = {
        width: `${size}px`,
        height: `${size}px`,
        left: `${left}vw`,
        animationDuration: `${animationDuration}s`,
    };

    return (
        <div
            className="absolute top-0 bg-white rounded-full opacity-80 pointer-events-none animate-fall shadow-md border border-gray-300"
            style={style}
        ></div>
    );
};

export default Snowflake;
