import { createContext, useContext, useState, ReactNode, FC, useCallback } from 'react'
import { generateUniqueId } from 'utils'
import { AiOutlineLoading } from "react-icons/ai";

interface ErrorContextState {
  handleError: (errorMessage: string, error?: unknown) => void
  handleSuccess: (successMessage: string) => { remove: () => void };
  handleLoading: (loadingMessage: string) => { remove: () => void };
}

interface MessageProviderProps {
  children: ReactNode
}

interface MessageType {
  id: string
  message: string
  subMessage?: string
  type: 'error' | 'success' | 'loading'
}

const ErrorContext = createContext<ErrorContextState | undefined>(undefined)

export const useMessage = (): ErrorContextState => {
  const context = useContext(ErrorContext)
  if (context === undefined) {
    throw new Error('useMessage must be used within an MessageProvider')
  }
  return context
}

export const MessageProvider: FC<MessageProviderProps> = ({ children }) => {

  const [messages, setMessages] = useState<MessageType[]>([])

  // Make sure to use useCallback to avoid infinite loops. These functions stay the same between renders
  const handleError = useCallback((errorMessage: string, error?: unknown) => {
    let subMessage: string | undefined = undefined;
    if (error instanceof Error) {
      subMessage = error.message
    } else if (typeof error === 'string') {
      subMessage = error;
    }

    setMessages(currentMessages => [...currentMessages, {
      id: generateUniqueId(),
      message: errorMessage,
      subMessage,
      type: 'error'
    }])
  }, [])

  // Make sure to use useCallback to avoid infinite loops. These functions stay the same between renders
  const handleLoading = useCallback((loadingMessage: string, autoRemove = true) => {
    const messageId = generateUniqueId()
    setMessages(currentMessages => [...currentMessages, {
      id: messageId,
      message: loadingMessage,
      type: 'loading'
    }])

    const removeMessage = () => setMessages(currentMessages =>
      currentMessages.filter(message => message.id !== messageId)
    )
    if (autoRemove) {
      setTimeout(removeMessage, 30000)
    }

    return { remove: removeMessage }
  }, [])
  
  // Make sure to use useCallback to avoid infinite loops. These functions stay the same between renders
  const handleSuccess = useCallback((successMessage: string, autoRemove = true) => {
    const messageId = generateUniqueId()
    setMessages(currentMessages => [...currentMessages, {
      id: messageId,
      message: successMessage,
      type: 'success'
    }])

    const removeMessage = () => setMessages(currentMessages =>
      currentMessages.filter(message => message.id !== messageId)
    )
    if (autoRemove) {
      setTimeout(removeMessage, 3000)
    }

    return { remove: removeMessage }
  }, [])

  const removeMessage = (index: number) => {
    // Create a new array by filtering out the item at the specified index
    const newMessages = messages.filter((_, i) => i !== index)
    setMessages(newMessages)
  }

  return (
    <ErrorContext.Provider value={{ handleError, handleSuccess, handleLoading }}>
      {children}

      {messages.map((msg, index) => (
        <div
          key={index}
          className={`${msg.type === 'error' ? 'animate-shake' : ''} flex items-center w-fit max-w-[96vw] p-4 text-gray-500 bg-white rounded-lg shadow-md fixed inset-x-0 mx-auto mb-4 border-solid border-2 border-gray-200 z-50`}
          style={{ bottom: `${index * 80}px` }} // Adjust top position based on error index
          role="alert"
        >

          {msg.type === 'error' ? (
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
          ) : null}
          {msg.type === 'loading' ? (
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg">
              <AiOutlineLoading className="animate-spin" />
              <span className="sr-only">Loading icon</span>
            </div>
          ) : null}
          {msg.type === 'success' ? (
            <div className="animate-ping-once inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Success icon</span>
            </div>
          ) : null}

          <div>
            <div className="ms-3 text-sm font-normal">{msg.message}</div>
            {msg.subMessage && <div className="ms-3 text-xs font-normal text-gray-400 italic break-all">{msg.subMessage}</div>}
          </div>
          <button
            type="button"
            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
            onClick={() => removeMessage(index)}
            aria-label="Close"
          >
            <span className="sr-only">Close</span>
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
          </button>
        </div>
      ))}


    </ErrorContext.Provider>
  )
}
