import classNames from 'classnames';
import moment from "moment";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import Button from 'Components/Library/Button';
import TruncateText from 'Components/Library/TruncateText';
import { ErrorEventModel } from 'types/shared';
import { useState } from 'react';

type Props = {
    errorEvents: ErrorEventModel[]
    allowedToResolve: boolean
    resolveErrorEvent: (errorEventId: string) => void
    resolveAllErrorEvents: () => void
}

const ErrorEvents = ({ errorEvents, allowedToResolve, resolveErrorEvent, resolveAllErrorEvents }: Props) => {

    const sortedEventsDesc = errorEvents
        .sort((a, b) => new Date(b.occuredAt).getTime() - new Date(a.occuredAt).getTime());

    const [showPayload, setShowPayload] = useState(false);

    return (
        <div className="p-4 md:p-5 overflow-y-auto max-h-80vh">

            {sortedEventsDesc.length === 0 && (
                <p className="text-gray-500">
                    No events have been recorded.
                </p>
            )}

            <ol className="relative border-s border-gray-400 ms-3.5 mb-4 md:mb-5">
                {sortedEventsDesc.map((event, index) => (
                    <li key={event.id} className={classNames("ms-8", { 'mb-10': index !== sortedEventsDesc.length - 1 })}>
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full -start-3.5 ring-8 ring-white">
                            {event.isResolved ? <FaCheckCircle className='text-green-500 w-3 h-3' /> : <FaMinusCircle className='text-red-500 w-3 h-3' />}
                        </span>
                        <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-800">
                            {event.title}

                            {index === 0 && (
                                <span className="bg-primary text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">Latest</span>
                            )}
                        </h3>
                        <h4 className="block mb-3 text-sm font-normal leading-none text-gray-500">
                            Resolved: {event.isResolved ? moment(event.resolvedAt).format('MMM Do, YYYY. HH:mm:ss') : 'No'}
                        </h4>

                        <div className="text-gray-900 mb-1">
                            <TruncateText text={event.message} />
                        </div>


                        {!showPayload ?
                            <span
                                className="text-primary cursor-pointer hover:underline"
                                onClick={() => setShowPayload(true)}
                            >
                                Show Payload
                            </span>
                            :
                            <code className="block whitespace-pre overflow-x-scroll">
                                {event.payload}
                            </code>
                        }

                        <time className="block my-3 text-sm font-normal leading-none text-gray-500">
                            {moment(event.occuredAt).format('MMM Do, YYYY. HH:mm:ss')}
                        </time>

                        {allowedToResolve && !event.isResolved && (
                            <Button
                                type="cta"
                                onClick={() => resolveErrorEvent(event.id)}
                            >
                                Resolve
                            </Button>
                        )}
                    </li>
                ))}
            </ol>

            {allowedToResolve && sortedEventsDesc.some(e => !e.isResolved) && (
                <div className="flex justify-end">
                    <Button
                        type="cta"
                        onClick={resolveAllErrorEvents}
                    >
                        Resolve All
                    </Button>
                </div>
            )}
        </div>
    )
}

export default ErrorEvents
