import { useCallback, useEffect, useState } from "react";
import { InboundForecast, InboundForecastGroup, InboundForecastWithSalesOrderReferences, PostponeInboundForecastDto, PostponeInboundForecastResponse } from "types/inbound-forecast-group";
import InboundForecastGroupProductTable from "Components/InboundForecastGroups/InboundForecastGroupProductTable";
import { useParams } from "react-router";
import { useApiClient } from 'Contexts/ApiClientContext';
import { PiLightbulbLight } from "react-icons/pi";
import moment from "moment";
import { PageHeader, Button, TextInput, PageSettings, PrinterSelector, Loading } from 'Components';
import { usePermissions } from "Contexts/PermissionsContext";
import Modal from "Components/Library/Modal";
import ForecastGroupEvents from "Components/InboundForecastGroups/ForecastGroupEvents";
import ErrorEvents from "Components/ErrorEvents";
import DateInput from "Components/Library/Form/DateInput";
import { useAuth0 } from "@auth0/auth0-react";
import PostponeResultTable from "Components/InboundForecastGroups/PostponeResultTable";

const CONFIGURED_LABEL_PRINTER_DESCRIPTION = 'Lexmark M5200 Series XL';

export default function InboundForecastGroupDetail() {
    const { purchaseOrderReference } = useParams();
    const apiClient = useApiClient();
    const { isAllowed, Permissions } = usePermissions();

    const [searchQuery, setSearchQuery] = useState('')
    const [inboundForecastGroup, setInboundForecastGroup] = useState<InboundForecastGroup | null>(null)
    const [filteredProducts, setFilteredProducts] = useState<InboundForecastWithSalesOrderReferences[] | null>(inboundForecastGroup?.inboundForecastsWithReferences ?? null)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [lastEnterPressed, setLastEnterPressed] = useState(0)
    const [selectedPrinter, setSelectedPrinter] = useState<number | null>(parseInt(localStorage.getItem('selectedInboundBarcodePrinter') ?? ''))
    const [selectedProducts, setSelectedProducts] = useState<InboundForecast[]>([])
    const [selectedPostponeDate, setSelectedPostponeDate] = useState<Date | null>(null)
    const [postponementResult, setPostponementResult] = useState<PostponeInboundForecastResponse | null>();

    const hasOpenLineItems = filteredProducts?.some(lineItem => lineItem.quantity > lineItem.quantityReceived) ?? false;
    const [showEventLog, setShowEventLog] = useState(false);
    const [showErrorLog, setShowErrorLog] = useState(false);
    const [showPostponeView, setShowPostponeView] = useState(false);
    const [postponementLoading, setPostponementLoading] = useState(false);

    const {
        user,
    } = useAuth0();

    useEffect(() => {
        const filtered = inboundForecastGroup?.inboundForecastsWithReferences.filter(product =>
            product.sku.toString().toLowerCase().includes(searchQuery.toLowerCase())
            || product.ean?.toString().toLowerCase().includes(searchQuery.toLowerCase())
            || product.description?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        ) ?? []
        setFilteredProducts(filtered)
    }, [searchQuery, inboundForecastGroup])

    const fetchInboundForecastGroup = useCallback(async () => {
        if (!purchaseOrderReference) return;
        try {
            const { data } = await apiClient.getInboundForecastGroup(purchaseOrderReference);
            if (data) {
                setInboundForecastGroup(data);
            }
        } catch (error) {
            console.error("Error fetching inbound forecast group:", error);
        }
    }, [apiClient, purchaseOrderReference]);

    useEffect(() => {
        fetchInboundForecastGroup();
    }, [fetchInboundForecastGroup]);

    const handlePrintBarcodes = async () => {
        if (buttonDisabled) return;
        if (!inboundForecastGroup) return;
        if (!filteredProducts) return;
        if (!selectedPrinter) return;

        setButtonDisabled(true);
        const timeout = setTimeout(() => setButtonDisabled(false), 20000)

        await apiClient.printInBoundForecastGroupLineItems(inboundForecastGroup.reference, filteredProducts, selectedPrinter);

        clearTimeout(timeout);
        setButtonDisabled(false);
    }

    const handleKeyPress = (key: string) => {
        if (Date.now() - lastEnterPressed < 200) {
            handlePrintBarcodes();
        }
        if (key === 'Enter') {
            setLastEnterPressed(Date.now());
        }
    };

    if (!inboundForecastGroup) {
        return (
            <></>
        );
    }

    const toggleSelectedProduct = (inboundForecastId: number, checked: boolean) => {
        if (checked) {
            const product = filteredProducts?.find(product => product.inboundForecastId === inboundForecastId)
            if (product) {
                setSelectedProducts([...selectedProducts, product])
            }
        } else {
            setSelectedProducts(selectedProducts.filter(product => product.inboundForecastId !== inboundForecastId))
        }
    }

    const selectAllProducts = () => {
        setSelectedProducts(filteredProducts ?? [])
    }

    const deselectAllProducts = () => {
        setSelectedProducts([])
    }

    const postponeSelectedInbounds = async () => {
        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot cancel line item");
            return;
        }
        if (!selectedProducts.length || !inboundForecastGroup.reference || !selectedPostponeDate) {
            console.error("No products selected or no inbound forecast group or no postpone date selected");
            return;
        }
        setPostponementLoading(true);

        var selectedInbounds = selectedProducts.map(product => {
            return {
                lineNumber: product.lineNumber,
                reference: inboundForecastGroup.reference,
                sku: product.sku,
                ean: product.ean,
                date: selectedPostponeDate?.toISOString() ?? ''
            } as PostponeInboundForecastDto
        }
        )
        const { data } = await apiClient.postponeInboundForecastLines(
            selectedInbounds,
            user.email
        );
        if (data) {
            setPostponementLoading(false);
            setPostponementResult(data);
            // Refetch after 2.5 seconds to give Monta some time to process the update and the polling to pick up the changes
            setTimeout(fetchInboundForecastGroup, 2_500);
        }
        setSelectedProducts([]);
    }

    const cancelSelectedInbounds = async () => {
        if (!selectedProducts.length || !inboundForecastGroup.mongoId) return;

        const selectedInboundIds = selectedProducts.map(product => product.inboundForecastId);
        const { data } = await apiClient.cancelInboundForecastLines(
            purchaseOrderReference!,
            inboundForecastGroup.mongoId,
            selectedInboundIds
        );

        if (data) {
            setInboundForecastGroup(data);
        }
        setSelectedProducts([]);
    }

    const handleEventLogClicked = () => {
        setShowEventLog(true);
    };

    const handleErrorLogClicked = () => {
        setShowErrorLog(true);
    };

    const handlePostponeViewClicked = () => {
        setShowPostponeView(true);
    }

    const handlePostponeViewClosed = () => {
        setShowPostponeView(false);
        setPostponementResult(null);
    }

    const handleErrorLogClosed = () => {
        setShowErrorLog(false);
    }

    const handleEventLogClosed = () => {
        setShowEventLog(false);
    }

    const handleResolveErrorItem = async (errorId: string) => {
        if (!inboundForecastGroup) return;

        var updatedEvent = await apiClient.resolveInboundForecastGroupErrorEvent(inboundForecastGroup.mongoId, errorId);
        if (!updatedEvent || updatedEvent == null) return;

        const eventIndex = inboundForecastGroup.errorEvents.findIndex(event => event.id === updatedEvent!.id);

        if (eventIndex === -1) return;

        const updatedErrorEvents = [...inboundForecastGroup.errorEvents];
        updatedErrorEvents[eventIndex] = updatedEvent;

        // Use update the inboundForecastGroup state with the new errorEvents array
        setInboundForecastGroup({ ...inboundForecastGroup, errorEvents: updatedErrorEvents });
    };

    const handleResolveAllErrors = async () => {
        if (!inboundForecastGroup) return;

        var updatedGroup = await apiClient.resolveAllInboundForecastGroupErrorEvents(inboundForecastGroup.mongoId);
        if (!updatedGroup) return;

        setInboundForecastGroup({ ...inboundForecastGroup, errorEvents: updatedGroup.errorEvents });
        setShowErrorLog(false);
    }

    const amountOfErrors = inboundForecastGroup.errorEvents.filter(e => !e.isResolved).length;

    return (
        <>
            <div className="flex justify-between">
                <PageHeader
                    title={`${inboundForecastGroup.reference} - ${inboundForecastGroup.supplier.name}`}
                    toolbar={<>
                        <Button onClick={handleErrorLogClicked} type="white">
                            Error Log {amountOfErrors > 0 && (<> ({amountOfErrors}) </>)}
                        </Button>
                        <Button onClick={handleEventLogClicked}>
                            Event Log
                        </Button>
                        <PageSettings>
                            <b>Labelprinter</b>
                            <PrinterSelector
                                storageKey="selectedInboundBarcodePrinter"
                                filter={CONFIGURED_LABEL_PRINTER_DESCRIPTION}
                                selectedPrinter={selectedPrinter ?? 0}
                                setSelectedPrinter={setSelectedPrinter}
                            />
                        </PageSettings>
                    </>}
                    metaData={[
                        `Created: ${moment(inboundForecastGroup.montaCreated).format('DD-MM-YYYY HH:mm')}`,
                        `Updated: ${moment(inboundForecastGroup.meta.updatedAt).format('DD-MM-YYYY HH:mm')}`
                    ]}
                />
            </div>

            <div className="pb-2 flex justify-between items-center">
                <TextInput
                    placeHolder="Search by SKU, EAN, Reference or supplier code"
                    value={searchQuery}
                    onChange={setSearchQuery}
                    onKeyPress={handleKeyPress}
                    withClearButton
                />
                {hasOpenLineItems ? (
                    <div className="flex gap-4 items-center">
                        <Button
                            onClick={handlePrintBarcodes}
                            disabled={buttonDisabled || !selectedPrinter}
                        >
                            {searchQuery ? 'Print filtered barcodes' : 'Print all barcodes'}
                        </Button>
                    </div>
                ) : <div className="text-gray-400">No labels to print</div>}
            </div>
            {searchQuery.length > 0 && hasOpenLineItems && (
                <div className="italic text-gray-400 flex gap-2 items-center">
                    <PiLightbulbLight /> Double press enter when searching to print filtered barcodes
                </div>
            )}

            {showEventLog && (
                <Modal title="Event Log" onClose={handleEventLogClosed} showBackdrop closeWithEscape>
                    <ForecastGroupEvents events={inboundForecastGroup.events} />
                </Modal>
            )}

            {showErrorLog && (
                <Modal title="Error Log" onClose={handleErrorLogClosed} showBackdrop closeWithEscape>
                    <ErrorEvents
                        errorEvents={inboundForecastGroup.errorEvents || []}
                        allowedToResolve={isAllowed(Permissions.RESOLVE_ERROR_EVENT_INBOUND_FORECASTS)}
                        resolveErrorEvent={handleResolveErrorItem}
                        resolveAllErrorEvents={handleResolveAllErrors}
                    />
                </Modal>
            )}

            {showPostponeView && (
                <Modal title="Postpone Inbound Forecasts" onClose={handlePostponeViewClosed} showBackdrop closeWithEscape>
                    {postponementLoading
                        ? <Loading />
                        : postponementResult
                            ? <PostponeResultTable postponeResult={postponementResult} />
                            :
                    <div className="flex flex-col">
                        <div className="flex flex-col p-4">
                            <div className="font-bold">Select date to postpone to:</div>
                            <DateInput
                                value=""
                                onChange={value => setSelectedPostponeDate(new Date(value))}
                            />
                        </div>
                        <Button
                            type="cta"
                            className="mt-5"
                            onClick={postponeSelectedInbounds}
                            disabled={!selectedPostponeDate}
                        >
                            Postpone
                        </Button>
                    </div>}
                </Modal>
            )}

            <InboundForecastGroupProductTable
                products={filteredProducts ?? []}
                selectedProducts={selectedProducts}
                toggleSelectedProduct={toggleSelectedProduct}
                selectAllProducts={selectAllProducts}
                deselectAllProducts={deselectAllProducts}
                inboundEvents={inboundForecastGroup.events}
            />

            <div className="flex justify-end mt-8">
                {isAllowed(Permissions.POSTPONE_INBOUND_FORECASTS) && (
                    <Button
                        type="cta"
                        onClick={handlePostponeViewClicked}
                        disabled={!selectedProducts.length}
                    >
                        Postpone selected inbounds
                    </Button>
                )}

                {isAllowed(Permissions.CANCEL_INBOUND_FORECASTS) && (
                    <Button
                        type="red"
                        className="ml-4"
                        onClick={cancelSelectedInbounds}
                        disabled={!selectedProducts.length}
                    >
                        Cancel selected inbounds
                    </Button>
                )}
            </div>
        </>
    );
}
