import classNames from "classnames";

type Props = {
    children: React.ReactNode;
    noBottomMargin?: boolean;
}

const PageTitle = ({ children, noBottomMargin = false }: Props) => (
    <>
        <h1 className={classNames("text-4xl font-extrabold leading-none tracking-tight text-gray-900", {
            "mb-4": !noBottomMargin
        })}>{children}</h1>
    </>
)

export default PageTitle
