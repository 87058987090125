import { MontaOrder } from "types/sales-order/montaOrder";

const PRINTING_SKU = "PF-bedrukken";
const EMBROIDERY_SKU = "PF-borduren";

export const CANCELLED_STATE = "Cancelled";
export const ORDER_BLOCKED_SPLIT_REASON = "In afwachting afronding order splitsing"

export function isPersoConeSku(sku: string): boolean {
    return sku === PRINTING_SKU || sku === EMBROIDERY_SKU;
}


export function canCancelOrder(order: MontaOrder): boolean {
    const isOrderBlockedToCancel = order.blocked && order.blockedMessage === ORDER_BLOCKED_SPLIT_REASON;

    return !isOrderBlockedToCancel
        && (!order.shipped
            && !order.picked
            && !order.picking
            && order.shipperCode !== CANCELLED_STATE
            && !order.profortoData.isDeleted
        );
}
