import { StoreEnum } from "types/sales-order";
import { Tooltip } from "@material-tailwind/react";

import { FaQuestion } from "react-icons/fa";
import ProfortoLogo from '../../media/icons/proforto-logo.svg';
import BolLogo from '../../media/icons/bol-logo.svg';
import TricorpLogo from '../../media/icons/tricorp-logo.svg';
import EmmaLogo from '../../media/icons/emma-logo.svg';
import { convertStoreEnumToLabel } from "utils/enums";

export default function StoreLogo({ store }: { store: StoreEnum }) {
    let imageSrc = '';
    let imageAlt = convertStoreEnumToLabel(store);

    switch (store) {
        case StoreEnum.ProfortoNL:
        case StoreEnum.ProfortoBE:
            imageSrc = ProfortoLogo;
            break;

        case StoreEnum.ProfortoBol:
            imageSrc = BolLogo;
            break;

        case StoreEnum.Tricorpstore:
            imageSrc = TricorpLogo;
            break;

        case StoreEnum.EmmaFootwear:
            imageSrc = EmmaLogo;
            break;

        default:
            return <FaQuestion className="text-red-500" />;
    }

    return (
        <Tooltip content={imageAlt}>
            <img src={imageSrc} alt={imageAlt} className="w-7 h-7 rounded-full bg-white p-1" />
        </Tooltip>
    );
}
