import classNames from 'classnames'

export const Table = ({ className, children, ...props }: React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>) => (
    <div className="rounded-lg shadow-lg border border-gray-200 w-full overflow-x-scroll">
        <table className={classNames('w-full text-sm text-gray-500 overflow-x-auto', className)} {...props}>
            {children}
        </table>
    </div>
)

export const Thead = ({ className, children, ...props }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => (
    <thead className={classNames('text-xs text-gray-700 uppercase text-left group thead bg-gray-200', className)} {...props}>
        {children}
    </thead>
)

export const Tbody = ({ children, ...props }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => (
    <tbody {...props}>
        {children}
    </tbody>
)

export const Tfoot = ({ className, children, ...props }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => (
    <tfoot className={classNames('group tfoot', className)} {...props}>
        {children}
    </tfoot>
)

export const Tr = ({ className, children, ...props }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => (
    <tr className={classNames('odd:bg-white even:bg-gray-100 transition-color', className)} {...props}>
        {children}
    </tr>
)

export const Th = ({ className, scope="col", children, ...props }: React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
    <th scope={scope} className={classNames('first:pl-6 last:pr-6 p-2 pt-3 bg-gray-200', className)} {...props}>
        {children}
    </th>
)

export const Td = ({ className, children, ...props }: React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
    <td className={classNames('first:pl-6 last:pr-6 p-2 group-[.tfoot]:py-3 border-gray-200 border-t', className)} {...props}>
        {children}
    </td>
)
