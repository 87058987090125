import { useNavigate } from "react-router-dom";
import { PurchaseOrder, PurchaseOrderMethodEnum } from "types/purchase-order";
import { formatToDutchDate, formatToDutchDateTime } from "utils/dates";
import { display } from "utils/pricing";
import { Table, Thead, Tbody, Tr, Th, Td } from 'Components';
import { Tooltip } from "@material-tailwind/react";
import { PiWarningFill } from "react-icons/pi";
import { PathConstants } from "utils/PathConstants";

export default function PurchaseOrderTable({ purchaseOrders }: { purchaseOrders: PurchaseOrder[] }) {

    const navigate = useNavigate();

    const onClickHandler = (e: React.MouseEvent<HTMLTableRowElement>, id: string) => {
        if (e) e.stopPropagation();

        if (e.metaKey || e.ctrlKey) {
            const win = window.open(PathConstants.PurchaseOrderDetail(id), "_blank");
            win?.focus();
        } else {
            navigate(id);
        }
    }

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>Order Number</Th>
                    <Th>Created At</Th>
                    <Th>Status</Th>
                    <Th>Method</Th>
                    <Th>Supplier</Th>
                    <Th className="text-right">Purchase Amount</Th>
                    <Th className="text-right">Expected Delivery Date</Th>
                </Tr>
            </Thead>
            <Tbody>
                {purchaseOrders.length === 0 && (
                    <Tr>
                        <Td colSpan={7}>
                            No purchase orders found
                        </Td>
                    </Tr>
                )}
                {purchaseOrders.map((purchaseOrder, index) => (
                    <Tr
                        key={index}
                        onClick={(e) => onClickHandler(e, purchaseOrder.mongoId)}
                        className="cursor-pointer hover:bg-gray-200"
                    >
                        <Td>
                            <div className="flex items-center">
                                {purchaseOrder.purchaseOrderNumber}
                                {purchaseOrder.isNeedsAttention && (
                                    <Tooltip content="Needs attention">
                                        <div>
                                            <PiWarningFill className="ml-2 mb-0.5 -mr-8 text-red-500 w-5 h-5" />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </Td>
                        <Td>{formatToDutchDateTime(purchaseOrder.meta.createdAt)}</Td>
                        <Td>{purchaseOrder.submitted ? 'Submitted' : 'Pending'}</Td>
                        <Td>{PurchaseOrderMethodEnum[purchaseOrder.purchase.method]}</Td>
                        <Td>{purchaseOrder.supplier.name}</Td>
                        <Td className="text-right">{display(purchaseOrder.price.totalPurchasePrice.value)}</Td>
                        <Td className="whitespace-nowrap text-right">
                            {formatToDutchDate(purchaseOrder.expectedDeliveryDate)}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
}
