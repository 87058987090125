import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'Components';
import { LuPackage2 } from "react-icons/lu";
import { MdForklift } from "react-icons/md";
import classNames from 'classnames';
import { Tooltip } from "@material-tailwind/react";
import { FaSleigh } from "react-icons/fa";

import NeedsAttentionBar from "./Header/NeedsAttentionBar";
import ShakeButton from "Components/ShakeButton";
import Snowflake from 'Components/Snowflake';

export interface SnowflakeType {
    id: number;
    size: number;
    left: number;
    animationDuration: number;
}


function Header() {
    const {
        loginWithRedirect,
        isAuthenticated,
        isLoading,
        user,
    } = useAuth0();

    const [active, setActive] = useState(false);
    const [clickCount, setClickCount] = useState(0);

    // Seasonal easter egg
    const [snowflakes, setSnowflakes] = useState<SnowflakeType[]>([]);

    const isHolidaySeason = (): boolean => {
        const now = new Date();
        const month = now.getMonth(); // December is 11
        const day = now.getDate();

        // Check if the date is between December 1st and December 31st
        return month === 11 && day >= 1 && day <= 31;
    };

    const generateSnowflakes = () => {
        const newSnowflakes = [] as any;
        for (let i = 0; i < 100; i++) {
            newSnowflakes.push({
                id: Math.random(),
                size: Math.random() * 10 + 5,
                left: Math.random() * 100,
                animationDuration: Math.random() * 3 + 2,
            });
        }
        setSnowflakes([...snowflakes, ...newSnowflakes])
    };
    // End of Seasonal easter egg

    // Used for easter egg
    let clickCountTimeout: NodeJS.Timeout;
    const handleClick = () => {
        setClickCount(clickCount + 1);
        clearTimeout(clickCountTimeout);
        clickCountTimeout = setTimeout(() => setClickCount(0), 1500);

        // This is the clickCount before this click, so this will actually be te second click
        if (clickCount > 1) {
            setActive(true);
            setTimeout(() => setActive(false), 5000);
        }
    }

    const isProduction = () => {
        return ['https://procurement.proforto.app', 'https://proforto.app'].includes(window.location.origin);
    }

    return (
        <header className="z-50 fixed w-screen">
            <nav className="flex bg-header-primary border-gray-200 py-2.5 shadow items-center">
                <div>
                    {isAuthenticated && user && (
                        <div
                            className="text-gray-800 font-medium text-sm focus:outline-none cursor-pointer relative"
                            onClick={handleClick}
                        >
                            <div className="relative min-w-[63px] h-[48px] box-content">
                                <div className="absolute left-1/2 -translate-x-1/2 bg-gray-50 rounded-full border-2 border-white h-[40px] w-[40px]">
                                    <img
                                        className="rounded-full"
                                        height={40}
                                        width={40}
                                        alt=""
                                        src={user.picture}
                                    />
                                </div>
                                <div className="absolute left-1/2 -translate-x-1/2 z-10 bottom-0 bg-gray-50 rounded-2xl px-1 whitespace-nowrap text-xs">
                                    {user.given_name?.split(' ')[0].split('-')[0]}
                                </div>
                            </div>
                            <LuPackage2 className={classNames("absolute -z-10 top-2 left-1/2 w-8 h-8", {
                                'animate-easterbox': active,
                                'invisible': !active,
                            })} />
                            <MdForklift className={classNames("absolute top-[calc(100vh-52px)] left-1/2 ml-2 -scale-x-100 h-10 w-10", {
                                'animate-easterfork': active,
                                'invisible': !active,
                            })} />
                        </div>
                    )}
                </div>
                <div className="flex justify-between w-full px-5 max-w-[1800px] mx-auto">
                    <div className="flex gap-2 items-end text-white py-3">
                        <Link to="/" className="flex items-center">
                            <span className="flex items-center whitespace-nowrap gap-4">
                                {!isProduction() && (
                                    <div className="w-6 h-6 flex items-center text-3xl">
                                        <Tooltip content="Staging">
                                            🚧
                                        </Tooltip>

                                    </div>
                                )}
                                <svg className="h-6 w-auto fill-white inline-block neon-glow-blue neon-hover-effect" viewBox="0 0 732 73">
                                    <g clipPath="url(#clip0_471_13378)">
                                        <path d="M419.191 0.0410156C392.561 0.0410156 369.95 11.4044 364.849 36.3662C359.748 61.3281 377.715 72.6961 404.344 72.6961C430.974 72.6961 453.684 61.3328 458.785 36.3662C463.886 11.3997 445.82 0.0410156 419.191 0.0410156ZM408.057 54.5335C395.474 54.5335 388.848 47.3453 391.088 36.3662C393.328 25.3871 402.895 18.2036 415.478 18.2036C428.061 18.2036 434.772 25.3918 432.546 36.3662C430.32 47.3406 420.64 54.5335 408.057 54.5335Z" fill="white" />
                                        <path d="M244.821 0.0410156C218.191 0.0410156 195.585 11.4044 190.479 36.3662C185.373 61.3281 203.349 72.6961 229.979 72.6961C256.609 72.6961 279.314 61.3328 284.415 36.3662C289.516 11.3997 271.45 0.0410156 244.821 0.0410156ZM233.687 54.5335C221.104 54.5335 214.478 47.3453 216.718 36.3662C218.958 25.3871 228.53 18.2036 241.113 18.2036C253.696 18.2036 260.406 25.3918 258.176 36.3662C255.945 47.3406 246.27 54.5335 233.687 54.5335Z" fill="white" />
                                        <path d="M561.624 2.37012L558.09 19.6612H585.99L575.638 70.3628H601.001L611.354 19.6612H639.155L642.689 2.37012H561.624Z" fill="white" />
                                        <path d="M691.545 0.0410156C664.915 0.0410156 642.304 11.4044 637.203 36.3662C632.102 61.3281 650.074 72.6961 676.703 72.6961C703.333 72.6961 726.043 61.3328 731.139 36.3662C736.235 11.3997 718.175 0.0410156 691.545 0.0410156ZM680.411 54.5335C667.828 54.5335 661.203 47.3453 663.447 36.3662C665.692 25.3871 675.254 18.2036 687.837 18.2036C700.42 18.2036 707.131 25.3918 704.9 36.3662C702.67 47.3406 692.995 54.5335 680.411 54.5335Z" fill="white" />
                                        <path d="M164.724 46.6611L175.881 70.3624H148.588L138.268 47.8279H121.488L116.885 70.3624H91.5215L99.6577 30.5322H152.235C156.823 30.5322 159.364 29.0796 160.155 25.1903C160.945 21.301 159.044 19.6562 154.461 19.6562H101.855L105.389 2.36523H163.312C183.02 2.36523 188.991 12.2712 186.37 25.1903C184.267 35.4946 177.491 43.8448 164.724 46.6611Z" fill="white" />
                                        <path d="M319.288 19.6611L317.283 29.4688H356.299L352.934 45.9819H314.206L309.302 69.9738L283.863 70.3627L297.755 2.37012H369.941L366.407 19.6611H319.288Z" fill="white" />
                                        <path d="M554.829 25.2002C557.469 12.2811 551.498 2.375 531.771 2.375H473.83L470.296 19.6661H522.873C527.461 19.6661 529.367 21.3156 528.572 25.2002C527.776 29.0848 525.235 30.5421 520.652 30.5421H468.075L459.938 70.3723H485.302L489.904 47.8377H506.681L517.005 70.3723H544.298L533.183 46.6663C545.931 43.85 552.707 35.4998 554.829 25.2002Z" fill="white" />
                                        <path d="M70.0777 2.37012H13.8913L10.3572 19.6612H61.1791C65.7625 19.6612 67.6682 21.3107 66.8777 25.1953C66.0871 29.0799 63.5415 30.5372 58.9581 30.5372H30.6484L3.55733 52.9546L0 70.3628H25.359L29.9659 47.8282H60.7885C80.4914 47.8282 90.477 38.1144 93.1169 25.1953C95.7569 12.2762 89.7806 2.37012 70.0777 2.37012Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_471_13378">
                                            <rect width="732" height="72.6551" fill="white" transform="translate(0 0.0410156)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </Link>
                        {isHolidaySeason() && (
                            <ShakeButton onShake={generateSnowflakes}>
                                <FaSleigh className="w-6 h-6" />
                            </ShakeButton>
                        )}
                        {snowflakes.map((snowflake) => (
                            <Snowflake
                                key={snowflake.id}
                                size={snowflake.size}
                                left={snowflake.left}
                                animationDuration={snowflake.animationDuration}
                            />
                        ))}

                    </div>
                    <NeedsAttentionBar />
                    {isAuthenticated || !!user || (
                        <Button
                            disabled={isLoading}
                            onClick={() => loginWithRedirect()}
                            className="shadow-none"
                        >
                            Login
                        </Button>
                    )}
                </div>
            </nav>
        </header>
    );
}

export default Header;
