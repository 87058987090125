import SupplierTable from "Components/Suppliers/SupplierTable";
import { Supplier } from "types/supplier";
import { useEffect, useState } from "react";
import { useApiClient } from '../Contexts/ApiClientContext';
import { Loading, PageHeader, TextInput } from 'Components';

export default function SupplierList() {

    const apiClient = useApiClient();
    const [searchQuery, setSearchQuery] = useState('')
    const [suppliers, setSuppliers] = useState(null as Supplier[] | null)
    const [filteredSuppliers, setFilteredSuppliers] = useState(suppliers)


    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const { data } = await apiClient.getSuppliers();
                if (data) {
                    setSuppliers(data);
                }
            } catch (error) {
                console.error("Error fetching suppliers:", error);
            }
        };

        fetchSuppliers();
    }, [apiClient]);

    useEffect(() => {
        if (!suppliers) return

        const filtered = suppliers.filter(supplier =>
            supplier.montaCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
            supplier.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            supplier.email?.toLowerCase().includes(searchQuery.toLowerCase())
        )
        setFilteredSuppliers(filtered)
    }, [searchQuery, suppliers])

    if (filteredSuppliers === null) return (
        <Loading />
    )

    return (
        <>
            <PageHeader
                title="Suppliers"
            />

            <div className="mb-4">
                <TextInput
                    placeHolder="Search by name or email"
                    value={searchQuery}
                    onChange={setSearchQuery}
                    withClearButton
                />
            </div>

            <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                <SupplierTable suppliers={filteredSuppliers} />
            </div>
        </>
    );
}
