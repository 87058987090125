import { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, NumberInput, TextInput } from 'Components';
import { Stock, RequisitionInput } from 'types/stock';
import { MdAddShoppingCart } from "react-icons/md";
import classNames from 'classnames';

type Props = {
    supplierId: string
    stocks: Stock[] | null
    handleAddItem: (requisition: RequisitionInput) => void
    actionPending: boolean
}

type AddItem = {
    quantity: number
}

const AddBySearch = ({ supplierId, stocks, handleAddItem, actionPending }: Props) => {
    const [addQuantity, setAddQuantity] = useState<Record<string, AddItem>>({});
    const [searchText, setSearchText] = useState<string>('');

    const handleChangeAddQuantity = (sku: string, quantity: number) => {
        setAddQuantity({
            ...addQuantity,
            [sku]: {
                ...addQuantity[sku],
                quantity,
            } as AddItem
        });
    }

    const onAddItem = async (sku: string) => {
        const correspondingStock = stocks?.find(stock => stock.product.sku === sku);
        if (!correspondingStock) return;

        handleAddItem({
            mongoId: null, // Null for new requisitions
            quantity: addQuantity[sku]?.quantity ?? 1,
            product: {
                sku,
                name: correspondingStock?.product.name ?? '',
                ean: correspondingStock?.product.ean ?? '',
                supplierMontaCode: supplierId
            },
        });
        handleChangeAddQuantity(sku, 1);
    }

    const filteredStocks = stocks?.filter(stock =>
        stock.product.sku.toLowerCase().includes(searchText.toLowerCase())
        || stock.product.ean?.toLowerCase().includes(searchText.toLowerCase())
        || stock.product.name?.toLowerCase().includes(searchText.toLowerCase())
    ).slice(0, 30) ?? []

    return (
        <div className="p-4">
            <b className="mb-2">Add new products using search</b>
            <p className="text-xs text-gray-500 italic mb-4">Start typing to search for products to add.</p>
            <TextInput
                placeHolder="Search by SKU, EAN or name"
                value={searchText}
                onChange={setSearchText}
                withClearButton
            />
            {searchText.length > 0 && (
                <div className="mt-4 mb-1">
                    {filteredStocks.length ? (
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Product name</Th>
                                    <Th>Sku</Th>
                                    <Th>Ean</Th>
                                    <Th className="text-right">Available</Th>
                                    <Th className="text-right">Minimum</Th>
                                    <Th className="text-right">Reserved</Th>
                                    <Th className="text-right">Forecasted</Th>
                                    <Th className="text-right">Step</Th>
                                    <Th className="w-32">To purchase</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredStocks?.map(stock => (
                                    <Tr key={stock.product.sku}>
                                        <Td>{stock.product.name}</Td>
                                        <Td>{stock.product.sku}</Td>
                                        <Td>{stock.product.ean}</Td>
                                        <Td className="text-right">{stock.amounts.available}</Td>
                                        <Td className="text-right">{stock.amounts.minimum}</Td>
                                        <Td className="text-right">{stock.amounts.reserved}</Td>
                                        <Td className="text-right">{stock.amounts.forecasted}</Td>
                                        <Td className="text-right">{stock.amounts.step}</Td>
                                        <Td className="flex justify-between items-center gap-4">
                                            <NumberInput
                                                min={stock.amounts.step}
                                                step={stock.amounts.step}
                                                value={addQuantity[stock.product.sku]?.quantity ?? 1}
                                                onChange={(value) => handleChangeAddQuantity(stock.product.sku, value)}
                                            />
                                            <MdAddShoppingCart
                                                className={classNames("h-6 w-6 text-gray-500 shrink-0 hover:text-gray-700 transition-all", {
                                                    'opacity-30 cursor-not-allowed': actionPending,
                                                    'cursor-pointer': !actionPending
                                                })}
                                                onClick={() => onAddItem(stock.product.sku)}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    ) : <div>No results to match your query.</div>}
                </div>
            )}
        </div>
    )
}

export default AddBySearch;
