import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MontaReturn } from "types/rma";
import { Table, Tbody, Td, Th, Thead, Tr } from "Components/Library/Table";
import { PathConstants } from "utils/PathConstants";
import { useState } from "react";

export default function RmaTable({ rmas }: { rmas: MontaReturn[] }) {
    const navigate = useNavigate();
    const [isSelectingText, setIsSelectingText] = useState(false);

    const onMouseDownHandler = () => {
        setIsSelectingText(false); // Reset on mouse down
    };

    const onMouseMoveHandler = () => {
        setIsSelectingText(true); // Detect movement (text selection)
    };

    const onClickHandler = (e: React.MouseEvent<HTMLTableRowElement>, returnId: number) => {
        if (e) e.stopPropagation();
        if (isSelectingText) return; // Ignore click if selecting text

        if (e.metaKey || e.ctrlKey) {
            const win = window.open(PathConstants.RmaDetail(returnId), "_blank");
            win?.focus();
        } else {
            navigate(returnId.toString());
        }
    }

    return (
        <>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Order #</Th>
                        <Th>Return Id</Th>
                        <Th>Email</Th>
                        <Th>All Accepted</Th>
                        <Th className="text-right">Lines</Th>
                        <Th className="text-right">Registered At</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rmas.length === 0 && (
                        <Tr>
                            <Td colSpan={6}>No RMAs found</Td>
                        </Tr>
                    )}
                    {rmas.map((rma, index) => (
                        <Tr
                            key={index}
                            className="cursor-pointer hover:bg-gray-200"
                            onMouseDown={onMouseDownHandler}
                            onMouseMove={onMouseMoveHandler}
                            onClick={(e) => onClickHandler(e, rma.returnId)}
                        >
                            <Td>{rma.reference.mage}</Td>
                            <Td>{rma.returnId}</Td>
                            <Td>{rma.customerEmail}</Td>
                            <Td>{rma.lines.every(line => line.isSellable) ? 'Yes' : 'No'}</Td>
                            <Td className="text-right">{rma.lines.length}</Td>
                            <Td className="text-right">{moment(rma.registeredAt).format('DD-MM-YYYY HH:mm')}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}
