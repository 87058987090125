import { InboundForecastWithSalesOrderReferences } from "types/inbound-forecast-group";
import moment from 'moment'
import { ForecastEvents, InboundForecast } from "types/inbound-forecast-group";
import { Checkbox, Table, Thead, Tbody, Tr, Th, Td } from 'Components'
import { Tooltip } from "@material-tailwind/react";
import { usePermissions } from "Contexts/PermissionsContext";
import { PathConstants } from "utils/PathConstants";
import { Link } from "react-router-dom";

type Props = {
    products: InboundForecastWithSalesOrderReferences[];
    selectedProducts: InboundForecast[];
    toggleSelectedProduct: (inboundForecastId: number, checked: boolean) => void
    selectAllProducts: () => void
    deselectAllProducts: () => void
    inboundEvents: ForecastEvents
};

export default function InboundForecastGroupProductTable({ products, selectedProducts, toggleSelectedProduct, selectAllProducts, deselectAllProducts, inboundEvents }: Props) {

    const { isAllowed, Permissions } = usePermissions();

    return (
        <Table>
            <Thead>
                <Tr>
                    {isAllowed(Permissions.CANCEL_INBOUND_FORECASTS) && (
                        <Th className="w-4">
                            {selectedProducts.length === products.length ? (
                                <Tooltip content="Deselect all" placement="bottom" followCursor={true}>
                                    <div>
                                        <Checkbox
                                            onChange={deselectAllProducts}
                                            checked={true}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip content="Select all" placement="bottom" followCursor={true}>
                                    <div>
                                        <Checkbox
                                            onChange={selectAllProducts}
                                            checked={false}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </Th>
                    )}
                    <Th className="whitespace-nowrap">#</Th>
                    <Th>Sku</Th>
                    <Th>Ean</Th>
                    <Th>Display Name</Th>
                    <Th>Expected Delivery Date</Th>
                    <Th>Amount Received</Th>
                    <Th>SalesOrder References</Th>
                </Tr>
            </Thead>
            <Tbody>
                {products.length === 0 && (
                    <Tr>
                        <Td colSpan={8}>
                            No Products found
                        </Td>
                    </Tr>
                )}
                {products.map((product) => (
                    <Tr key={product.inboundForecastId}>
                        {isAllowed(Permissions.CANCEL_INBOUND_FORECASTS) && (
                            <Td className="w-4">
                                <Checkbox
                                    onChange={checked => toggleSelectedProduct(product.inboundForecastId, checked)}
                                    checked={!!selectedProducts.find((selectedProduct) => selectedProduct.inboundForecastId === product.inboundForecastId)}
                                />
                            </Td>
                        )}
                        <Td className="w-4 text-right">{product.lineNumber}</Td>
                        <Td>{product.sku}</Td>
                        <Td>{product.ean}</Td>
                        <Td>{product.description}</Td>
                        <Td>{moment(product.deliveryDate).format('DD-MM-YYYY')}</Td>
                        <Td>{`${product.quantityReceived}/${product.quantity}`}</Td>
                        <Td>{
                            product.salesOrderReferences
                                ?.map((ref, i) =>
                                    <div>
                                        <Link className="underline" to={PathConstants.SalesOrderDetail(ref)}>{ref}</Link>
                                        {i < product.salesOrderReferences!.length - 1 && <span>, </span>}
                                    </div>
                                )
                        }</Td>
                    </Tr>
                ))}

                {products.length > 0 && (
                    <Tr>
                        <Td colSpan={8}>
                            Cancelled Items
                        </Td>
                    </Tr>
                )}

                {inboundEvents.removed.map((event, index) => (
                    <Tr
                        key={index}
                        className=" text-red-500"
                    >
                        <Td></Td>
                        <Td></Td>
                        <Td>{event.sku}</Td>
                        <Td>{event.ean}</Td>
                        <Td>{event.description}</Td>
                        <Td>-</Td>
                        <Td>{event.quantity} Cancelled</Td>
                        <Td></Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
}
