export interface Supplier {
    id: string | null
    montaCode: string
    name: string
    email: string | null
    phone: string | null

    address: Address

    // Proforto Date (editable)
    profortoData: ProfortoData

    meta: MetaData
}

export interface SupplierAsOption {
    montaCode: string
    name: string
}


export interface Address {
    street: string
    houseNumber: string
    houseNumberAddition: string | null
    postalCode: string
    city: string
    state: string | null
    countryCode: string
}

export interface ProfortoData {
    exactGuid: string | null
    intakeTimeAmsterdam: string | null
    isPurchaseThresholdNotMet: boolean,
    lastFailedPurchaseDate: Date | null
    purchaseMethod: PurchaseMethodEnum
    purchaseThreshold: {
        value: number
    }
    orderDays: OrderDaysEnum[] | null
    purchaseEmail: string | null
    vendorProcDays: number | null
    notifyPurchaseMancoEmails?: boolean
    mancoDeliveryEmails?: string[]
    rtvDay: number | null
    rtvWeeks: Array<number> | null
    rtvAddPoNumber: boolean
    gln: string | null
    nonProcessingDates: string[] // List of DateOnly
}

export enum OrderDaysEnum {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export enum PurchaseMethodEnum {
    None = 0,
    Email = 1,
    Transus = 2,
}

export interface MetaData {
    createdAt: Date
    updatedAt: Date
}
