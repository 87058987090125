import classNames from 'classnames';
import moment from "moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import { ForecastEvents } from 'types/inbound-forecast-group';

type Props = {
    events: ForecastEvents;
}

enum EventTypeEnum {
    Unknown = 0,
    Postponed = 1,
    Removed = 2
}

type ForecastEvent = {
    eventType: EventTypeEnum;
    sku: string;
    quantity: number;
    eventDate: string;
    eventDescription: string;
}

const ForecastGroupEvents = ({ events }: Props) => {

    // Combine all events into one array and sort them by date (map to one type)
    const allEvents = [
        ...events.postponed.map(event => ({
            eventType: EventTypeEnum.Postponed,
            sku: event.sku,
            quantity: event.quantity,
            eventDate: event.eventDate,
            eventDescription: event.eventDescription
        } as ForecastEvent)),
        ...events.removed.map(event => ({
            eventType: EventTypeEnum.Removed,
            sku: event.sku,
            quantity: event.quantity,
            eventDate: event.eventDate,
            eventDescription: event.eventDescription
        } as ForecastEvent)
        )
    ] as ForecastEvent[];

    const sortedEventsDesc = allEvents
        .sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime());

    return (
        <div className="p-4 md:p-5 overflow-y-auto max-h-96">

            {sortedEventsDesc.length === 0 && (
                <p className="text-gray-500">
                    No events have been recorded for this order.
                </p>
            )}

            <ol className="relative border-s border-gray-400 ms-3.5 mb-4 md:mb-5">
                {sortedEventsDesc.map((event, index) => (
                    <li key={index} className={classNames("ms-8", { 'mb-5': index !== sortedEventsDesc.length - 1 })}>
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full -start-3.5 ring-8 ring-white">
                            {event.eventType === EventTypeEnum.Postponed && (
                                <FaRegCalendarAlt className='text-gray-500 w-3 h-3' />
                            )}
                            {event.eventType === EventTypeEnum.Removed && (
                                <FaRegCalendarAlt className='text-gray-500 w-3 h-3' />
                            )}
                        </span>
                        <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-800">
                            {EventTypeEnum[event.eventType]}
                        </h3>
                        <h4 className="block mb-3 text-sm font-normal leading-none text-gray-500">
                            SKU: {event.sku}
                        </h4>

                        <p className="text-gray-900">
                            {event.eventDescription}
                        </p>

                        <time className="block mb-3 text-sm font-normal leading-none text-gray-500">
                            {moment(event.eventDate).format('MMM Do, YYYY. HH:mm:ss')}
                        </time>
                    </li>
                ))}
            </ol>
        </div>
    )
}

export default ForecastGroupEvents
