import { useDebouncedCallback } from "use-debounce";
import { useState, useEffect } from 'react'

type Props = {
    label?: React.ReactNode;
    placeHolder?: string;
    min?: number;
    step?: number
    max?: number;
    value: number;
    onChange: (newValue: number) => void;
    className?: string;
    debounce?: number;
    prefix?: string;
}
const NumberInput = ({ label, placeHolder, value, min = 0, step = 1, max, onChange, className, debounce = 10, prefix }: Props) => {
    const [val, setVal] = useState(value);

    useEffect(() => {
        setVal(value)
    }, [value])

    const _onChange = useDebouncedCallback(onChange, debounce)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Math.floor(step) === step
            ? parseInt(e.target.value)
            : parseFloat(e.target.value);

        if (isNaN(newValue)) return;

        setVal(newValue);
        _onChange(newValue);
    }

    return (
        <div className={className}>
            {label && (
                <label className="font-bold">{label}</label>
            )}
            <div className="relative mt-1 flex gap-2 items-center min-w-12 w-full">
                {prefix && (
                    <span className="text-gray-500">
                        {prefix}
                    </span>
                )}
                <div className="items-center">
                    <input
                        type="number"
                        className="inline-block py-2 ps-3 text-sm text-gray-900 border border-gray-300 w-full rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder={placeHolder}
                        min={min}
                        step={step}
                        max={max}
                        value={val}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default NumberInput
