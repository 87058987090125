import { PageHeader, Button } from 'Components';
import ModuleGrid from './Components/ModuleGrid';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  return (
    <>
        <>
            <PageHeader title="Welcome to the Proforto App!" />
            {!isAuthenticated ? (
                <p>To continue, please <Button className="inline-flex" onClick={() => loginWithRedirect()} size="small">login</Button> first.</p>
            ) : (
                <>
                    <p>Click on one of the modules below to get started.</p>
                    <ModuleGrid />
                </>
            )}
        </>
        <div className="fixed h-screen w-screen top-0 left-0 bg-white z-50 flex justify-center items-center flex-col md:hidden">
            <PageHeader title="Proforto App" />
            <p>This app is not available on mobile devices.</p>
            <div className="text-center justify-self-end p-2 text-sm text-gray-300 italic">
                v{window.PROCUREMENT_APP_VERSION}
            </div>
        </div>
    </>
  );
}

export default App;
