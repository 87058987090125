import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from "use-debounce";

export enum InputType {
  Default = 'default',
  Email = 'email',
}

const EmailInput = (
  { values, setValues, inputType = InputType.Default }
    : { values: string[], setValues: React.Dispatch<React.SetStateAction<string[]>>, inputType?: InputType }) => {

  /**
   * Validates the input value based on the input type
   * @returns true if the input value is valid, false otherwise
   */
  const validateInput = useCallback((inputValue: string): boolean => {
    let isValid = true;

    if (!inputValue) {
      setIsValidInput(true);
      return true;
    }

    switch (inputType) {
      case InputType.Email:
        // Regex pattern for validating email addresses
        const emailPattern = /^[\w%+-]+(\.[\w%+-]+)*@[\w%+-]+(\.[\w%+-]+)+$/;
        isValid = emailPattern.test(inputValue);
        break;

      case InputType.Default:
      default:
        break;
    }

    setIsValidInput(isValid);
    return isValid;
  }, [inputType]);

  const [inputValue, setInputValue] = useState('');
  const [isValidInput, setIsValidInput] = useState(true);
  const [debouncedInputValue] = useDebounce(inputValue, 200);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  // Perform validation whenever debouncedInputValue changes
  useEffect(() => {
    validateInput(debouncedInputValue);
  }, [debouncedInputValue, validateInput]);

  const handleInputKeyDown = (e: { key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault(); // Prevent default behavior of ',' key
      addValue();
    }
  };

  const addValue = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue) {
      const isValid = validateInput(trimmedValue);

      if (isValid) {
        setValues([...values, trimmedValue]);
        setInputValue('');
      }
    }
  };

  const removeValue = (emailIndex: number) => {
    setValues(values.filter((_, index) => index !== emailIndex));
  };

  const validationInputClass = isValidInput
    ? 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500'
    : 'bg-red-50 border-red-500 text-red-900';

  const placeHolderValue = inputType === InputType.Email
    ? 'Enter email addresses separated by comma'
    : 'Enter values separated by comma';

  return (
    <div>
      <div className='min-h-10 mb-1 border border-gray-300 rounded'>
        {values.map((email, index) => (
          <div
            key={index}
            className='inline py-2'
          >
            <div className='inline-flex bg-gray-100 p-1 m-1'>
              <span>{email}</span>
              <button className='ml-2 my-auto' onClick={() => removeValue(index)}>
                <svg className="w-4 h-4 text-gray-900 hover:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 18 6m0 12L6 6" />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
      <input
        type="text"
        className={`${validationInputClass} border text-sm rounded-lg block w-full p-2.5`}
        value={inputValue}
        onChange={(e) => handleInputChange(e.target.value)}
        onKeyDown={handleInputKeyDown}
        placeholder={placeHolderValue}
      />
    </div>
  );
};

export default EmailInput;
