import React, { useState } from 'react';
import Button from './Button';

interface TruncateTextProps {
    text: string | null
    maxLength?: number
}

const TruncateText: React.FC<TruncateTextProps> = ({ text, maxLength = 250 }) => {

    const [isTruncated, setIsTruncated] = useState(true);
    const toggleTruncate = () => setIsTruncated(!isTruncated);

    if (!text) {
        return null;
    }

    return (
        <div>
            <p className="text-gray-800">
                {isTruncated && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
            </p>
            {text.length > maxLength && (
                <Button
                    onClick={toggleTruncate}
                    className="mt-2 shadow-none"
                >
                    {isTruncated ? 'Show more' : 'Show less'}
                </Button>
            )}
        </div>
    );
};

export default TruncateText;
