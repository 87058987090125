import classNames from 'classnames';

type Props = {
    children: React.ReactNode;
    className?: string;
}

const Card = ({ children, className }: Props) => {
    return (
        <div className={classNames("block p-6 bg-gray-50 border border-gray-200 rounded-lg shadow", className)}>
            {children}
        </div>
    )
}

export default Card
