import { useState, useEffect } from 'react';
import { Stock, RequisitionInput } from 'types/stock';
import papa from 'papaparse';
import classNames from 'classnames';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Loading } from 'Components';

const parseCsv = (file: File, requiredColumns?: string[]) => {
    return new Promise((resolve, reject) => {
        papa.parse(file, {
            header: true,
            complete: (result) => {
                if (requiredColumns) {
                    const missingColumns = requiredColumns.filter(column => !result.meta.fields?.includes(column));
                    if (missingColumns.length) {
                        reject(`Missing columns: ${missingColumns.join(', ')}`);
                        return;
                    }
                }
                resolve(result);
            }
        });
    })
}

type Props = {
    supplierId: string
    stocks: Stock[] | null
    handleAddItems: (requisitions: RequisitionInput[]) => void
    actionPending: boolean
}

type IncorrectRequisition = RequisitionInput & { incorrect: { sku: boolean, quantity: boolean } }

const AddByImport = ({ supplierId, stocks, handleAddItems, actionPending }: Props) => {
    const [parsedRows, setParsedRows] = useState<any[]>();
    const [requisitionsToAdd, setRequisitionsToAdd] = useState<RequisitionInput[]>();
    const [incorrectRequisitions, setIncorrectRequisitions] = useState<IncorrectRequisition[]>();
    const [fileError, setFileError] = useState<string | null>();

    const onAddItems = () => {
        if (!requisitionsToAdd) return;
        handleAddItems(requisitionsToAdd);
    }

    useEffect(() => {
        const _requisitionsToAdd: RequisitionInput[] = []
        const _incorrectRequisitions: IncorrectRequisition[] = []
        if (!parsedRows || !stocks) return;
        for (const row of parsedRows) {
            if (!row.Sku || !row.Quantity) continue; // Empty row

            const quantity = Number(row.Quantity) || -1;

            const correspondingStock = stocks?.find(stock => stock.product.sku === row.Sku);
            if (!correspondingStock || quantity <= 0) {
                _incorrectRequisitions.push({
                    mongoId: null, // Null for new requisitions
                    quantity,
                    product: {
                        sku: row.Sku,
                        name: correspondingStock?.product.name ?? row.Description ?? 'Unknown',
                        ean: correspondingStock?.product.ean ?? row.ean ?? 'Unknown',
                        supplierMontaCode: supplierId,
                    },
                    incorrect: {
                        sku: !correspondingStock,
                        quantity: quantity <= 0
                    }
                });
                continue;
            };

            _requisitionsToAdd.push({
                mongoId: null, // Null for new requisitions
                quantity: quantity,
                product: {
                    sku: row.Sku,
                    name: correspondingStock?.product.name ?? '',
                    ean: correspondingStock?.product.ean ?? '',
                    supplierMontaCode: supplierId,
                },
            });

        }
        setRequisitionsToAdd(_requisitionsToAdd);
        setIncorrectRequisitions(_incorrectRequisitions);
        setFileError(null);
    }, [parsedRows, stocks, supplierId])

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        const file = e.target.files[0];
        try {
            const rows = await parseCsv(file, ['Sku', 'Quantity']);
            setParsedRows((rows as any)?.data ?? []);
        } catch (error: any) {
            console.error("Error parsing file:", error);
            setFileError(error.toString());
        }
    }

    return (
        <div>
            <div className="p-4">
                <b className="mb-4">Add new products using import</b>
                {/* TODO: add EAN as optional */}
                <p className="text-xs text-gray-500 italic mb-4">Upload a csv file to start. Required columns are <code>'Sku'</code> and <code>'Quantity'</code>. Optional: <code>'Ean'</code></p>
                <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                />
                {fileError && (
                    <div className="text-red-600 text-xs mt-2">Invalid Import file: {fileError}</div>
                )}
            </div>
            {parsedRows?.length && !stocks?.length && (
                <div className="px-4 my-6">
                    <Loading text="Waiting for stocks..." />
                </div>
            )}
            {!!incorrectRequisitions?.length && (
                <div>
                    <p className="font-bold mt-6 mb-2 px-4">The following requisitions contain errors:</p>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Product name</Th>
                                <Th>Sku</Th>
                                <Th>Ean</Th>
                                <Th className="w-32">Quantity</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {incorrectRequisitions?.map(requisition => (
                                <Tr key={requisition.product.sku}>
                                    <Td>{requisition.product.name}</Td>
                                    <Td className={classNames({
                                        'text-red-600 font-bold': requisition.incorrect.sku
                                    })}>
                                        {requisition.product.sku}
                                    </Td>
                                    <Td>{requisition.product.ean}</Td>
                                    <Td className={classNames({
                                        'text-red-600 font-bold': requisition.incorrect.quantity
                                    })}>
                                        {requisition.quantity >= 1 ? requisition.quantity : 'Invalid'}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            )}
            {!!requisitionsToAdd?.length && (
                <div>
                    <p className="font-bold mt-6 mb-2 px-4">The following requisitions will be added:</p>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Product name</Th>
                                <Th>Sku</Th>
                                <Th>Ean</Th>
                                <Th className="w-32">Quantity</Th>
                                <Th className="w-32">Order</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {requisitionsToAdd?.map(requisition => (
                                <Tr key={requisition.product.sku}>
                                    <Td>{requisition.product.name}</Td>
                                    <Td>{requisition.product.sku}</Td>
                                    <Td>{requisition.product.ean}</Td>
                                    <Td>{requisition.quantity}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            )}
            {!!incorrectRequisitions?.length && (
                <div className="m-4">
                    <div className="bg-red-500 text-white px-4 py-1 rounded-md inline-block">
                        Warning! {incorrectRequisitions.length} requisition{incorrectRequisitions.length > 1 ? 's' : ''} contain errors and will not be added.
                    </div>
                </div>
            )}
            {!!requisitionsToAdd?.length && (
                <Button
                    className="m-4 block"
                    disabled={actionPending}
                    onClick={onAddItems}
                >
                    Add products
                </Button>
            )}
        </div>
    )
}

export default AddByImport;
