import { PiWarningFill } from "react-icons/pi"
import { useNavigate } from "react-router-dom"
import { PurchaseMethodEnum, Supplier } from "types/supplier"
import { Tooltip } from "@material-tailwind/react"
import { Table, Thead, Tbody, Tr, Th, Td } from 'Components';
import { PathConstants } from "utils/PathConstants";
import { useState } from "react";

export default function SupplierTable({ suppliers }: { suppliers: Supplier[] }) {
    const navigate = useNavigate()
    const [isSelectingText, setIsSelectingText] = useState(false);

    const onMouseDownHandler = () => {
        setIsSelectingText(false); // Reset on mouse down
    };

    const onMouseMoveHandler = () => {
        setIsSelectingText(true); // Detect movement (text selection)
    };

    const onClickHandler = (e: React.MouseEvent<HTMLTableRowElement>, supplierId: string) => {
        if (e) e.stopPropagation();
        if (isSelectingText) return; // Ignore click if selecting text

        if (e.metaKey || e.ctrlKey){
            const win = window.open(PathConstants.SupplierDetail(supplierId), "_blank");
            win?.focus();
        } else {
            navigate(supplierId);
        }
    }

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th className="w-20">Code</Th>
                    <Th>Name</Th>
                    <Th>E-mail</Th>
                    <Th>Phone</Th>
                    <Th>Purchase Method</Th>
                </Tr>
            </Thead>
            <Tbody>
                {suppliers.length === 0 && (
                    <Tr>
                        <Td colSpan={5}>
                            No suppliers found
                        </Td>
                    </Tr>
                )}
                {suppliers.map((supplier, index) => (
                    <Tr
                        key={index}
                        onMouseDown={onMouseDownHandler}
                        onMouseMove={onMouseMoveHandler}
                        onClick={(e) => onClickHandler(e, supplier.montaCode)}
                        className="cursor-pointer hover:bg-gray-50"
                    >
                        <Td className="w-20">{supplier.montaCode}</Td>
                        <Td className="whitespace-nowrap flex items-center">
                            {supplier.name}
                            {!supplier.profortoData.exactGuid && (
                                <Tooltip content="Missing ExactGuid">
                                    <div>
                                        <PiWarningFill className="ml-2 mb-0.5 -mr-8 text-red-500 w-5 h-5" />
                                    </div>
                                </Tooltip>
                            )}
                        </Td>
                        <Td>{supplier.email}</Td>
                        <Td>{supplier.phone}</Td>
                        <Td>{PurchaseMethodEnum[supplier.profortoData.purchaseMethod]}</Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}
