import moment from "moment";
import { useNavigate } from "react-router-dom";
import { InboundForecastGroup } from "types/inbound-forecast-group";
import { Table, Thead, Tbody, Tr, Th, Td } from "Components";
import { PiWarningFill } from "react-icons/pi";
import { Tooltip } from "@material-tailwind/react";
import { PathConstants } from "utils/PathConstants";
import { useState } from "react";

export default function InboundForecastGroupTable({ inboundForecastGroups }: { inboundForecastGroups: InboundForecastGroup[] }) {

    const navigate = useNavigate();
    const [isSelectingText, setIsSelectingText] = useState(false);

    const onMouseDownHandler = () => {
        setIsSelectingText(false); // Reset on mouse down
    };

    const onMouseMoveHandler = () => {
        setIsSelectingText(true); // Detect movement (text selection)
    };

    const onClickHandler = (e: React.MouseEvent<HTMLTableRowElement>, purchaseOrderReference: number) => {
        if (e) e.stopPropagation();
        if (isSelectingText) return; // Ignore click if selecting text

        if (e.metaKey || e.ctrlKey) {
            const win = window.open(PathConstants.InboundForecastGroupDetail(purchaseOrderReference), "_blank");
            win?.focus();
        } else {
            navigate(purchaseOrderReference.toString());
        }
    }

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>PO Reference</Th>
                    <Th>Supplier</Th>
                    <Th className="text-right">
                        Number of items
                    </Th>
                    <Th className="text-right">
                        Created
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {inboundForecastGroups.length === 0 && (
                    <Tr>
                        <Td colSpan={5}>
                            No Inbound Forecast Groups found
                        </Td>
                    </Tr>
                )}
                {inboundForecastGroups.map((inboundForecastGroup, index) => (
                    <Tr
                        key={index}
                        className="cursor-pointer hover:bg-gray-200"
                        onClick={(e) => onClickHandler(e, inboundForecastGroup.purchaseOrderReference)}
                            onMouseDown={onMouseDownHandler}
                            onMouseMove={onMouseMoveHandler}
                        >
                        <Td>
                            <div className="flex items-center">
                                {inboundForecastGroup.reference}
                                {inboundForecastGroup.isNeedsAttention && (
                                    <Tooltip content="Needs attention">
                                        <div>
                                            <PiWarningFill className="ml-2 mb-0.5 -mr-8 text-red-500 w-5 h-5" />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </Td>
                        <Td>
                            {inboundForecastGroup.supplier.name}
                        </Td>
                        <Td className="text-right">
                            {inboundForecastGroup.inboundForecasts.length}
                        </Td>
                        <Td className="text-right">
                            {moment(inboundForecastGroup.montaCreated).format('DD-MM-YYYY HH:mm')}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
}
