import { PurchaseOrderSubmitSelectEnum } from "types/purchase-order";
import { StoreEnum } from "types/sales-order";

export function convertStoreEnumToLabel(store: StoreEnum): string {
    switch (store) {
        case StoreEnum.All:
            return "All Stores";
        case StoreEnum.ProfortoNL:
            return "Proforto NL";
        case StoreEnum.ProfortoBE:
            return "Proforto BE";
        case StoreEnum.ProfortoBol:
            return "Proforto Bol";
        case StoreEnum.EmmaFootwear:
            return "Emmafootwear";
        case StoreEnum.Tricorpstore:
            return "Tricorpstore";

        default:
            return "";
    }
}

export enum PersoSelectEnum {
    Any_Perso = "",
    With_Perso = "true",
    Without_Perso = "false"
}

export function convertPersoSelectEnumToLabel(persoSelect: PersoSelectEnum): string {
    switch (persoSelect) {
        case PersoSelectEnum.Any_Perso:
            return "Any Perso";
        case PersoSelectEnum.With_Perso:
            return "With Perso";
        case PersoSelectEnum.Without_Perso:
            return "Without Perso";
        default:
            return "";
    }
}

export function convertPOSubmitSelectEnumToLabel(poSubmitSelect: PurchaseOrderSubmitSelectEnum): string {
    switch (poSubmitSelect) {
        case PurchaseOrderSubmitSelectEnum.Any:
            return "Any";
        case PurchaseOrderSubmitSelectEnum.Submitted:
            return "Submitted";
        case PurchaseOrderSubmitSelectEnum.NotSubmitted:
            return "Pending";
        default:
            return "";
    }
}
