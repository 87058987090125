import classNames from 'classnames';
import { Link } from 'react-router-dom'

type Route = {
    path: string;
    label: string;
}

type Props = {
    children: React.ReactNode;
    routes: Route[]
}

const Tabs = ({ children, routes }: Props) => {
    const currentRoute = window.location.pathname;

    return (
        <div className="my-4">
            <div className="flex gap-1">
                {routes.map(route => (
                    <Link
                        className={classNames("transition-all rounded-t-lg text-lg relative z-10 after:shadow-lg before:* before:w-full before:h-2 before:block before:-bottom-1 before:absolute group", {
                            'bg-white font-bold before:bg-white ': currentRoute === route.path,
                            'hover:bg-gray-50 before:group-hover:bg-gray-50': currentRoute !== route.path
                        })}
                        to={route.path}
                        key={route.path}
                    >
                        <div className="py-2 px-4">
                            {route.label}
                        </div>
                    </Link>
                ))}
            </div>
            <div className={classNames("p-8 bg-white rounded-lg shadow-md", {
                'rounded-tl-none': routes[0].path === currentRoute,
            })}>
                {children}
            </div>
        </div>
    )
}

export default Tabs;
