


import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "@material-tailwind/react";
import { MdSell as SalesIcon, MdFormatListNumbered as PoIcon, MdPallet as IbfIcon } from "react-icons/md";
import { usePermissions, useApiClient } from "Contexts";

const UPDATE_FREQUENCY = 1000 * 60 * 5; // Every 5 minutes

function NeedsAttentionBar () {
    const {
        isAuthenticated,
    } = useAuth0();

    const apiClient = useApiClient();
    const { isAllowed, Permissions } = usePermissions();
    const [poNeedsAttentionCount, setPoNeedsAttentionCount] = useState<number | null>(null);
    const [soNeedsAttentionCount, setSoNeedsAttentionCount] = useState<number | null>(null);
    const [ibfNeedsAttentionCount, setIbfNeedsAttentionCount] = useState<number | null>(null);
    
    useEffect(() => {
        let lastUpdate = 0;
        let interval: NodeJS.Timer;

        const fetchNeedsAttentionCounts = async () => {
            if (
                !isAuthenticated || (!document.hasFocus() && lastUpdate !== 0) // Only reload when the tab is focused
                || new Date().getTime() - lastUpdate < UPDATE_FREQUENCY - 1000 // Litle margin to prevent race-conditions
                
            ) return;
            lastUpdate = new Date().getTime();

    
            if (isAllowed(Permissions.READ_PURCHASE_ORDERS)) {
                apiClient.getPurchaseOrderNeedsAttentionCount().then(res => {
                    setPoNeedsAttentionCount(res.data);
                });
            }

            if (isAllowed(Permissions.READ_SALES_ORDERS)) {
                apiClient.getSalesOrdersNeedsAttentionCount().then(res => {
                    setSoNeedsAttentionCount(res.data);
                });
            }
            
            if (isAllowed(Permissions.READ_INBOUND_FORECASTS)) {
                apiClient.getInboundForecastNeedsAttentionCount().then(res => {
                    setIbfNeedsAttentionCount(res.data);
                });
            }
        }
        fetchNeedsAttentionCounts();

        window.addEventListener('focus', () => {
            fetchNeedsAttentionCounts();
            // reset interval because we might now be out of sync
            clearInterval(interval);
            interval = setInterval(fetchNeedsAttentionCounts, UPDATE_FREQUENCY);
        });
        interval = setInterval(fetchNeedsAttentionCounts, UPDATE_FREQUENCY);
        return () => {
            window.removeEventListener('focus', fetchNeedsAttentionCounts);
            clearInterval(interval)
        }
    }, [isAuthenticated, Permissions, apiClient, isAllowed]);

    if (!isAuthenticated) return null;

    return (
        <div className="flex items-center lg:order-2 gap-4">
            {soNeedsAttentionCount && (
                <Tooltip className="z-50" content={`${soNeedsAttentionCount} sales orders need attention`}>
                    <Link className="relative" to="/sales-orders?needs-attention=true">
                        <SalesIcon className="text-white text-3xl" />
                        <div className="absolute -top-1 -right-1 bg-red-500 rounded-full text-xs text-white p-1 min-w-5 leading-none text-center">
                            {soNeedsAttentionCount}
                        </div>
                    </Link>
                </Tooltip>
            )}
            {poNeedsAttentionCount && (
                <Tooltip className="z-50" content={`${poNeedsAttentionCount} purchase orders need attention`}>
                    <Link className="relative" to="/purchase-orders?needs-attention=true">
                        <PoIcon className="text-white text-3xl" />
                        <div className="absolute -top-1 -right-1 bg-red-500 rounded-full text-xs text-white p-1  min-w-5 leading-none text-center">
                            {poNeedsAttentionCount}
                        </div>
                    </Link>
                </Tooltip>
            )}
            {ibfNeedsAttentionCount && (
                <Tooltip className="z-50" content={`${ibfNeedsAttentionCount} inbound forecasts need attention`}>
                    <Link className="relative" to="/inbound-forecasts?needs-attention=true">
                        <IbfIcon className="text-white text-3xl" />
                        <div className="absolute -top-1 -right-1 bg-red-500 rounded-full text-xs text-white p-1 min-w-5 leading-none text-center">
                            {ibfNeedsAttentionCount}
                        </div>
                    </Link>
                </Tooltip>
            )}
        </div>
    )
}

export default NeedsAttentionBar;
