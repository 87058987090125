import { useEffect, useState, ChangeEvent, useCallback, useRef } from "react";
import { InboundForecastGroup, PostponeInboundForecastDto, PostponeInboundForecastResponse, InboundForecastGroupsSearchCriteria } from "types/inbound-forecast-group";
import InboundForecastGroupTable from "Components/InboundForecastGroups/InboundForecastGroupTable";
import { useApiClient } from 'Contexts/ApiClientContext';
import { Pagination } from "utils/apiClient";
import { Button, Loading, PageHeader, Pager, Table, Thead, Tbody, Tr, Th, Td, TextInput, Select } from 'Components';
import Modal from "Components/Library/Modal";
import papa from 'papaparse';
import moment from "moment";
import { formatToDutchDate } from "utils/dates";
import { useAuth0 } from "@auth0/auth0-react";
import { useDebounce } from "use-debounce";
import { SupplierAsOption } from "types/supplier";
import { isEmpty, TryParseInt } from "utils";
import { useMessage } from "Contexts";
import Toggle from "Components/Library/Form/Toggle";
import { useNavigate, useLocation } from "react-router-dom";
import PostponeResultTable from "Components/InboundForecastGroups/PostponeResultTable";
import { IoClose } from "react-icons/io5";

const parseCsv = (file: File, requiredColumns?: string[]) => {
    return new Promise((resolve, reject) => {
        papa.parse(file, {
            header: true,
            complete: (result) => {
                if (requiredColumns) {
                    const missingColumns = requiredColumns.filter(column => !result.meta.fields?.includes(column));
                    if (missingColumns.length) {
                        reject(`Missing columns: ${missingColumns.join(', ')}`);
                        return;
                    }
                }
                resolve(result);
            }
        });
    })
}

interface PostponeInboundForecast extends PostponeInboundForecastDto {
    line: number;
}

interface SearchCriteria {
    poNumber: string,
    supplierMontaCode: string,
    sku: string,
    isNeedsAttention: boolean,
}

export default function InboundForecastGroupList() {
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        user,
    } = useAuth0();
    const { handleError } = useMessage();

    const [inboundForecasts, setInboundForecasts] = useState(null as InboundForecastGroup[] | null)
    const [pagination, setPagination] = useState<Pagination | null>(null)
    const [parsedPostponeRows, setParsedPostponeRows] = useState([] as PostponeInboundForecast[]);
    const [ignoredPostponeRows, setIgnoredPostponeRows] = useState([] as any[]); // TODO: NOT use any
    const [showPostponeDialog, setShowPostponeDialog] = useState(false);
    const [postponeByInbound, setPostponeByInbound] = useState(false);
    const [postponementLoading, setPostponementLoading] = useState(false);
    const [postponementResult, setPostponementResult] = useState<PostponeInboundForecastResponse | null>();
    const [supplierOptions, setSupplierOptions] = useState<SupplierAsOption[]>([]);

    // Retrieve initial search parameters from sessionStorage or default values
    const initialSearchParams = (): SearchCriteria => {
        const storedParams = sessionStorage.getItem('searchParamsInbound');
        return storedParams ? JSON.parse(storedParams) : {
            poNumber: "",
            supplierMontaCode: "",
            sku: "",
            isNeedsAttention: location.search.includes('needs-attention=true')
        };
    };

    const [searchParams, setSearchParams] = useState<SearchCriteria>(initialSearchParams);
    const [debouncedSearchParams] = useDebounce(searchParams, 300);
    const previousSearchCriteria = useRef<InboundForecastGroupsSearchCriteria | null>(null);

    const handleSearchParamChange = (param: keyof SearchCriteria, value: any) => {
        if (param === "isNeedsAttention") {
            navigate({
                search: value
                    ? `${location.search ? location.search + '&' : '?'}needs-attention=true`
                    : location.search.replace('?needs-attention=true', '').replace('needs-attention=true', '')
            }, { replace: true });
        }

        setSearchParams(prevState => ({
            ...prevState,
            [param]: value
        }));
    };
    const handleClearSearchParams = () => {
        sessionStorage.removeItem('searchParamsInbound');
        setSearchParams(initialSearchParams);
    }

    const fetchInboundForecasts = useCallback(async () => {
        try {
            const handleErrorNull = (error: string) => {
                handleError(error);
                return null;
            }
            const parsedPoNumber: { result?: number } = {};
            const searchCriteria: InboundForecastGroupsSearchCriteria = {
                poNumbers: debouncedSearchParams.poNumber
                    ? TryParseInt(debouncedSearchParams.poNumber, parsedPoNumber)
                        ? [parsedPoNumber.result!]
                        : handleErrorNull(`'${debouncedSearchParams.poNumber}' is not a number :)`)
                    : null,
                supplierMontaCode: isEmpty(searchParams.supplierMontaCode)
                    ? null
                    : searchParams.supplierMontaCode,
                sku: isEmpty(debouncedSearchParams.sku)
                    ? null
                    : debouncedSearchParams.sku,
                isNeedsAttention: debouncedSearchParams.isNeedsAttention ? true : null,
                page: pagination?.page ?? 1,
            }
            if (searchCriteria.poNumbers === previousSearchCriteria.current?.poNumbers
                && searchCriteria.supplierMontaCode === previousSearchCriteria.current?.supplierMontaCode
                && searchCriteria.sku === previousSearchCriteria.current?.sku
                && searchCriteria.isNeedsAttention === previousSearchCriteria.current?.isNeedsAttention
                && searchCriteria.page === previousSearchCriteria.current?.page) {
                // No need to fetch again if the search criteria hasn't changed
                return;
            }
            previousSearchCriteria.current = searchCriteria;

            const { data } = await apiClient.getInboundForecastGroups(searchCriteria, pagination?.page ?? 1, pagination?.pageSize ?? 30);
            if (data) {
                setInboundForecasts(data.data);
                if (JSON.stringify(data.pagination) !== JSON.stringify(pagination)) {
                    setPagination({
                        ...data.pagination,
                        page: pagination?.page ?? 1
                    });
                }
            }
        } catch (error) {
            console.error("Error fetching inbound forecast groups:", error);
            setInboundForecasts([])
        }
    }, [debouncedSearchParams.poNumber, debouncedSearchParams.sku, debouncedSearchParams.isNeedsAttention, searchParams.supplierMontaCode, pagination, apiClient, handleError]);

    // Store searchParams in sessionStorage whenever they change
    useEffect(() => {
        sessionStorage.setItem('searchParamsInbound', JSON.stringify(debouncedSearchParams));
    }, [debouncedSearchParams]);

    useEffect(() => {
        const fetchSupplierOptions = async () => {
            try {
                const { data } = await apiClient.getSuppliersAsOptions();

                if (data) {
                    setSupplierOptions(data);
                }
            } catch (error) {
                console.error("Error fetching suppliers as options:", error);
            }
        };

        fetchSupplierOptions();
    }, [apiClient]);

    useEffect(() => {
        fetchInboundForecasts();
    }, [apiClient, fetchInboundForecasts]);

    const handleNextPageClick = () => {
        if (!pagination?.hasNextPage) return;
        setPagination({ ...pagination, page: pagination?.page + 1 } as Pagination);
    };
    const handlePreviousPageClick = () => {
        if (!pagination?.hasPreviousPage) return;
        setPagination({ ...pagination, page: pagination.page - 1 } as Pagination);
    };

    const handleUploadPostponeByInboundClicked = () => {
        setShowPostponeDialog(true);
        setPostponeByInbound(true);
    };
    const handleUploadPostponeByProductClicked = () => {
        setShowPostponeDialog(true);
        setPostponeByInbound(false);
    }

    const handleUploadPostponeClosed = () => {
        setShowPostponeDialog(false);
        setParsedPostponeRows([]);
        setIgnoredPostponeRows([]);
        setPostponementLoading(false);
        setPostponementResult(null);
    }

    if (inboundForecasts === null) return (
        <Loading />
    )

    const handlePostponeUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        setParsedPostponeRows([])
        setIgnoredPostponeRows([])
        const file = e.target.files?.[0];
        if (!file) return;

        try {
            const data = [];
            const ignoredRows = [];
            const rows = await parseCsv(file, ['date', ...(postponeByInbound ? ['po_number'] : [])]);
            let line = 0;
            for (const row of (rows as any).data) {
                line++;
                if (postponeByInbound) {
                    row.reference = row.po_number;
                }
                const parsedDate = moment.utc(row.date, 'DD-MM-YYYY').toISOString();
                if ((postponeByInbound && !row.po_number) || (!row.sku && !row.ean) || !parsedDate) {
                    ignoredRows.push({ line, ...row });
                    continue;
                };
                row.date = parsedDate;
                data.push({ line, ...row })
            }
            setParsedPostponeRows(data);
            setIgnoredPostponeRows(ignoredRows);
        } catch (error: any) {
            console.error("Error parsing file:", error);
        }
    }

    const executePostponement = async () => {
        if (!parsedPostponeRows.length) return;
        setPostponementLoading(true);

        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot cancel line item");
            return;
        }

        const author = user.email;

        try {
            const data = parsedPostponeRows.map(row => ({
                lineNumber: row.line,
                reference: row.reference,
                sku: row.sku,
                ean: row.ean ?? '',
                date: moment(row.date).format('YYYY-MM-DD')
            }));
            const { data: result } = postponeByInbound
                ? await apiClient.postponeInboundForecasts(data, author)
                : await apiClient.postponeInboundProducts(data, author);
            setPostponementLoading(false);
            setPostponementResult(result);

            setPagination({ ...pagination, page: 1 } as Pagination);
            fetchInboundForecasts();
        } catch (error) {
            console.error("Error postponing inbound forecasts:", error);
            setPostponementLoading(false);
            setShowPostponeDialog(false);
        }
    }

    const supplierOptionList = () => {
        const sortedList = supplierOptions
            .map(supplier => ({
                value: supplier.montaCode,
                label: supplier.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))

        sortedList.unshift({ value: "", label: "All Suppliers" });

        return sortedList;
    }

    return (
        <>
            <PageHeader
                title="Inbound Forecasts"
                toolbar={(
                    <div>
                        <Button
                            onClick={handleUploadPostponeByProductClicked}
                            type="cta"
                            className="mr-2"
                        >
                            Postpone by Product
                        </Button>
                        <Button
                            onClick={handleUploadPostponeByInboundClicked}
                            type="cta"
                        >
                            Postpone by Inbound
                        </Button>
                    </div>
                )}
            />
            <div className="flex items-center gap-x-3 mb-2">
                <TextInput
                    className="w-72"
                    placeHolder="Search by PO number (exact match)"
                    value={searchParams.poNumber}
                    onChange={(value) => handleSearchParamChange("poNumber", value)}
                    withClearButton
                />
                <TextInput
                    placeHolder="Search by sku"
                    className="w-60"
                    value={searchParams.sku}
                    onChange={(newValue) => handleSearchParamChange("sku", newValue)}
                    withClearButton
                />
                <Select
                    className="!w-72"
                    value={!searchParams.supplierMontaCode ? "All Suppliers" : searchParams.supplierMontaCode}
                    onChange={(newValue) => { handleSearchParamChange("supplierMontaCode", newValue) }}
                    options={supplierOptionList()}
                />
                <Button type="white" className="mt-1 w-5 h-10 shadow-none" onClick={handleClearSearchParams}>
                    <IoClose className="-my-2 -mx-2" />
                </Button>
                <Toggle
                    className="ml-auto"
                    label={"Needs Attention"}
                    value={searchParams.isNeedsAttention}
                    onChange={(newValue) => handleSearchParamChange("isNeedsAttention", newValue)}
                />
            </div>

            {showPostponeDialog && (
                <Modal title={`Postpone ${postponeByInbound ? "Inbound Forecasts" : "Products"} using import`} onClose={handleUploadPostponeClosed}>
                    {postponementLoading
                        ? <Loading />
                        : (
                            postponementResult
                                ? <PostponeResultTable postponeResult={postponementResult} />
                                : (
                                    <div>
                                        <p className="mb-2">Upload a csv file to start.</p>
                                        <p className="text-gray-500 italic mb-4">Required columns are
                                            {postponeByInbound && <code>'po_number'</code>}
                                            <code>'date'</code>
                                            and either
                                            <code>'sku'</code>
                                            or
                                            <code>'ean'</code>.
                                        </p>
                                        <p className="text-sm text-gray-500 italic mb-4">The dates should be in format <code>'DD-MM-YYYY'</code>.</p>
                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={handlePostponeUpload}
                                        />
                                        {ignoredPostponeRows.length > 0 && (
                                            <div className="mt-4">
                                                <b className="block text-red-900 mb-1">Ignored rows:</b>
                                                <Table>
                                                    <Thead>
                                                        <Th className="w-8">Line</Th>
                                                        {postponeByInbound && <Th>PO number</Th>}
                                                        <Th>Sku</Th>
                                                        <Th>Ean</Th>
                                                        <Th>Date</Th>
                                                    </Thead>
                                                    <Tbody>
                                                        {ignoredPostponeRows.map(row => (
                                                            <Tr key={row.line}>
                                                                <Td className="text-right w-8">{row.line}</Td>
                                                                {postponeByInbound && <Td>{row.reference}</Td>}
                                                                <Td>{row.sku}</Td>
                                                                <Td>{row.ean}</Td>
                                                                <Td>{formatToDutchDate(row.date)}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        )}
                                        {parsedPostponeRows.length > 0 && (
                                            <div className="mt-4 overflow-y-auto max-h-96	">
                                                <b className="block mb-1">Parsed rows:</b>
                                                <Table>
                                                    <Thead>
                                                        <Th className="w-8">Line</Th>
                                                        {postponeByInbound && <Th>PO number</Th>}
                                                        <Th>Sku</Th>
                                                        <Th>Ean</Th>
                                                        <Th>Date</Th>
                                                    </Thead>
                                                    <Tbody>
                                                        {parsedPostponeRows.map(row => (
                                                            <Tr key={row.line}>
                                                                <Td className="text-right w-8">{row.line}</Td>
                                                                {postponeByInbound && <Td>{row.reference}</Td>}
                                                                <Td>{row.sku}</Td>
                                                                <Td>{row.ean}</Td>
                                                                <Td>{formatToDutchDate(row.date)}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                                <div className="flex justify-end mt-4">
                                                    <Button onClick={executePostponement} type='cta'>
                                                        Postpone lines
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                        )
                    }
                </Modal>
            )}

            <InboundForecastGroupTable inboundForecastGroups={inboundForecasts} />
            {pagination && (
                <Pager
                    currentPage={pagination.page}
                    pageSize={pagination.pageSize}
                    totalItems={pagination.totalItems}
                    handleNextPageClick={handleNextPageClick}
                    handlePreviousPageClick={handlePreviousPageClick}
                />
            )}

        </>
    );
}
