import { BiPackage } from "react-icons/bi";
import { AiOutlineLoading } from "react-icons/ai";

type Props = {
    text?: string
}

const Loading = ({ text }: Props) => {
    return (
        <div className="flex justify-center items-center flex-col text-gray-400">
            <div className="relative">
                <BiPackage className="h-6 w-6 absolute top-3 left-3" />
                <AiOutlineLoading className="h-12 w-12 animate-spin" />
            </div>
            <div className="text-center">
                {text ?? "Loading..."}
            </div>
        </div>
    );
}

export default Loading;
