import classNames from "classnames";
import { useEffect } from "react";

type Props = {
    children: React.JSX.Element;
    title: string;
    showBackdrop?: boolean;
    closeWithEscape?: boolean;
    closeOnBackdropClick?: boolean;
    onClose: () => void;
}

const Modal = ({ children, title, showBackdrop = true, closeWithEscape = true, closeOnBackdropClick = true, onClose }: Props) => {

    useEffect(() => {
        const close = (e: { keyCode: number; }) => {
            if (!closeWithEscape) return;

            if (e.keyCode === 27) {
                onClose();
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [closeWithEscape, onClose])

    const handleBackdropClick = () => {
        if (closeOnBackdropClick) {
            onClose();
        }
    }

    return (
        <>
            <div id="timeline-modal" aria-hidden="true" className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-70 flex justify-center items-center">
                <div className="relative bg-white rounded-lg m-4 max-w-screen-2xl max-h-[calc(100vh-4%)] overflow-y-auto">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 pb-2 border-b rounded-t">
                            <h3 className="text-lg font-semibold text-gray-800">
                                {title}
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-800 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center"
                                data-modal-toggle="timeline-modal"
                                onClick={onClose}
                            >
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4">
                            {children}
                        </div>
                    </div>
                </div>
            </div>

            {showBackdrop && (
                <div className={classNames("bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-60", {
                    'cursor-pointer': closeOnBackdropClick
                })} onClick={handleBackdropClick} />
            )}
        </>
    );
}

export default Modal;
