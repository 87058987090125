import React, { createContext, useContext, ReactNode, useMemo } from 'react'
import ApiClient from '../utils/apiClient'
import { useAuth0 } from "@auth0/auth0-react";
import { useMessage } from 'Contexts/MessageContext'

interface ApiClientProviderProps {
    children: ReactNode
    baseUrl?: string
}

const ApiClientContext = createContext<ApiClient | undefined>(undefined)

export const useApiClient = () => {
    const context = useContext(ApiClientContext)
    if (context === undefined) {
        throw new Error('useApiClient must be used within an ApiClientProvider')
    }
    return context
}

export const ApiClientProvider: React.FC<ApiClientProviderProps> = ({ children, baseUrl }) => {
    const { handleError, handleLoading, handleSuccess } = useMessage()
    const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();

    // We are sure that the client will not change during the lifetime of the app.
    // Any re-renders will not change the client. > Causing infinite loop in requests.
    const client = useMemo(() => new ApiClient(
        { baseUrl, handleError, handleLoading, handleSuccess, getAccessTokenSilently, isLoading, isAuthenticated }
    ), [baseUrl, handleError, handleLoading, handleSuccess, getAccessTokenSilently, isLoading, isAuthenticated])

    return (
        <ApiClientContext.Provider value={client}>
            {children}
        </ApiClientContext.Provider>
    )
}
