import classNames from "classnames";

type Props = {
    label?: string
    checked: boolean
    onChange: (checked: boolean) => void
    className?: string
}

const Checkbox = ({ label, checked, onChange, className }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    }
    const randomId = `checkbox-${Math.round(Math.random() * 100_000_000_000)}`;
    return (
        <div className={classNames("flex items-center my-2", className)}>
            <input
                id={randomId}
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-none cursor-pointer"
            />
            {label && (
                <label
                    htmlFor={randomId}
                    className="ms-2 text-sm cursor-pointer"
                >
                    {label}
                </label>
            )}
        </div>
    )
}

export default Checkbox
