
export interface IPathConstants
{
    SupplierList(): string;
    SupplierDetail(id: string): string;
    SupplierDetail(): string;
    SupplierToPurchase(id: string): string;
    SupplierToPurchase(): string;
    PurchaseOrderList(): string;
    PurchaseOrderDetail(id: string): string;
    PurchaseOrderDetail(): string;
    InboundForecastGroupList(): string;
    InboundForecastGroupDetail(purchaseOrderReference: number): string;
    InboundForecastGroupDetail(): string;
    Inventory(): string;
    SalesOrderList(): string;
    SalesOrderDetail(mageReference: string): string;
    SalesOrderDetail(): string;
    RmaList(): string;
    RmaDetail(returnId: number): string;
    RmaDetail(): string;
    MontaRateInfo(): string;
}

export const PathConstants : IPathConstants =
{
    SupplierList: () => `/suppliers`,
    SupplierDetail: (id?: string) => id
        ? `/suppliers/${id}`
        : `/suppliers/:id`,
    SupplierToPurchase: (id?: string) => id
        ? `/suppliers/${id}/to-purchase`
        : `/suppliers/:id/to-purchase`,
    PurchaseOrderList: () => `/purchase-orders`,
    PurchaseOrderDetail: (id?: string) => id
        ? `/purchase-orders/${id}`
        : `/purchase-orders/:id`,
    InboundForecastGroupList: () => `/inbound-forecasts`,
    InboundForecastGroupDetail: (purchaseOrderReference?: number) => purchaseOrderReference
        ? `/inbound-forecasts/${purchaseOrderReference}`
        : `/inbound-forecasts/:purchaseOrderReference`,
    Inventory: () => `/inventory`,
    SalesOrderList: () => `/sales-orders`,
    SalesOrderDetail: (mageReference?: string ) => mageReference
        ? `/sales-orders/${mageReference}`
        : `/sales-orders/:mageReference`,
    RmaList: () => `/rmas`,
    RmaDetail: (returnId?: number) => returnId
        ? `/rmas/${returnId}`
        : `/rmas/:returnId`,
    MontaRateInfo: () => `/monta-rate-info`,
}
