import { useRouteError } from "react-router-dom";
import { PageHeader } from "Components";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <PageHeader title="Oops!" />
      <p>Sorry, an unexpected error has occurred.</p>
    </>
  );
}
