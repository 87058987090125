import { FaPaintBrush, FaRegCalendarAlt } from 'react-icons/fa';
import { MontaOrder as TMontaOrder, MontaOriginEnum } from 'types/sales-order/montaOrder';
import { Tooltip } from "@material-tailwind/react";
import Accordion from 'Components/Library/Accordion';
import { MdBlock, MdCancel, MdOutlineTimer } from 'react-icons/md';
import { FaTruckRampBox } from 'react-icons/fa6';
import { TbTruckDelivery } from 'react-icons/tb';
import { SalesOrder } from 'types/sales-order';
import { displayPersoDetails } from 'Pages/SalesOrderDetail';
import { formatToDutchDate, formatToDutchDateTime, sortByDateDesc } from 'utils/dates';
import Modal from 'Components/Library/Modal';
import Select from 'Components/Library/Form/Select';
import Button from 'Components/Library/Button';
import { useState, useEffect } from 'react';
import { usePermissions, Permissions } from 'Contexts';
import { CancelLineItem } from 'utils/apiClient';
import { canCancelOrder, CANCELLED_STATE, isPersoConeSku } from 'utils/montaConstants';
import { returnReasonOther, returnReasons } from 'utils/customerServiceConstants';
import { Table, Thead, Tbody, Tr, Th, Td, TextInput, NumberInput } from 'Components';

import classNames from 'classnames';
import { PathConstants } from 'utils/PathConstants';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

type MontaOrderProps = {
    salesOrder: SalesOrder;
    order: TMontaOrder;
    handleCancellation: (webshopOrderId: string, reason: string, lineItem?: CancelLineItem) => void
}

const MontaOrder = ({ salesOrder, order, handleCancellation }: MontaOrderProps) => {
    const { isAllowed } = usePermissions();
    const CANCEL_ALL_ITEMS = "all";

    const {
        user,
    } = useAuth0();

    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [lineItemToCancel, setLineItemToCancel] = useState(CANCEL_ALL_ITEMS);
    const [lineItemQuantityToCancel, setLineItemQuantityToCancel] = useState(1);

    const correspondingOrderLineToCancel = order.lines.find(line => line.sku === lineItemToCancel);
    const [cancelationReason, setCancelationReason] = useState(returnReasonOther);
    const [cancelationReasonOther, setCancelationReasonOther] = useState("");

    const [actionLogModelOpen, setActionLogModelOpen] = useState(false);

    useEffect(() => {
        setLineItemQuantityToCancel(correspondingOrderLineToCancel?.orderedQuantity ?? 1)
    }, [correspondingOrderLineToCancel])

    const handleSubmitCancellation = () => {
        setCancelModalOpen(false);

        const matchedSku = order.lines.find(line => line.webshopOrderLineId === lineItemToCancel)?.sku ?? "";

        const cancelItem: CancelLineItem | undefined = lineItemToCancel === CANCEL_ALL_ITEMS ? undefined : {
            webshopOrderLineId: lineItemToCancel,
            sku: matchedSku,
        }
        if (cancelItem && !matchedSku) {
            throw new Error(`No sku found for line item to cancel by lineId ${lineItemToCancel}!`);
        }

        handleCancellation(
            order.webshopOrderId,
            returnReasonOther === cancelationReason
                ? cancelationReasonOther
                : cancelationReason,
            cancelItem,
        )

        setLineItemToCancel(CANCEL_ALL_ITEMS);
    }

    const renderOrderState = (order: TMontaOrder) => {
        if (order.shipperCode === CANCELLED_STATE || order.profortoData.isDeleted) {
            return (
                <Tooltip content={CANCELLED_STATE}>
                    <div className="inline-block mr-1">
                        <MdCancel className="inline-block mr-1 text-red-500 h-6 w-6" />
                    </div>
                </Tooltip>
            );
        } else if (order.blocked) {
            return (
                <Tooltip content="Blocked">
                    <div className="inline-block mr-1">
                        <MdBlock className="inline-block mr-1 text-red-500 h-6 w-6" />
                    </div>
                </Tooltip>
            );
        } else if (order.shipped) {
            return (
                <Tooltip content="Shipped">
                    <div className="inline-block mr-1">
                        <TbTruckDelivery className="inline-block mr-1 text-green-400 h-6 w-6" />
                    </div>
                </Tooltip>
            );
        } else if (order.picking) {
            return (
                <Tooltip content="Picking">
                    <div className="inline-block mr-1">
                        <FaTruckRampBox className="inline-block animate-bounce mr-1 text-primary h-6 w-6" />
                    </div>
                </Tooltip>
            );
        } else if (!order.shipped && !order.blocked) {
            return (
                <Tooltip content="In Progress">
                    <div className="inline-block mr-1">
                        <MdOutlineTimer className="text-primary h-6 w-6" />
                    </div>
                </Tooltip>
            );
        }
    }

    // Mike easter egg
    const cancelAllOptionLabel = user?.email?.toLowerCase() === "mike.bolder@proforto.nl"
        && Math.random() < 0.25 // 25% chance
        ? "Annuleer heel de kutzooi maar"
        : "All";

    return (
        <>
            {cancelModalOpen && (
                <Modal
                    title="Cancel line items"
                    onClose={() => setCancelModalOpen(false)}
                >
                    <>
                        <b className="mb-2">Which line items would you like to cancel?</b>
                        <Select
                            value={lineItemToCancel}
                            onChange={setLineItemToCancel}
                            options={[{
                                value: "all",
                                label: cancelAllOptionLabel
                            },
                            ...order.lines.filter(line => !isPersoConeSku(line.sku)).map((line) => ({
                                value: line.webshopOrderLineId ?? "", // TODO: empty should never occure with our own orders
                                label: `${line.sku} - ${line.description}`
                            }))]}
                        />
                        {correspondingOrderLineToCancel && correspondingOrderLineToCancel.orderedQuantity > 1 && (
                            <NumberInput
                                className="mt-2"
                                label="Quantity"
                                value={lineItemQuantityToCancel}
                                onChange={setLineItemQuantityToCancel}
                                min={1}
                                max={correspondingOrderLineToCancel.orderedQuantity}
                            />
                        )}

                        <span className='block mt-2'>Reason</span>
                        <Select
                            value={cancelationReason}
                            onChange={setCancelationReason}
                            options={[{
                                value: "other",
                                label: "Other"
                            },
                            ...returnReasons
                            ]}
                        />

                        {cancelationReason === returnReasonOther && (
                            <TextInput
                                label="Other reason"
                                value={cancelationReasonOther}
                                onChange={setCancelationReasonOther}
                                className="mt-2"
                                maxLength={100}
                            />
                        )}

                        <div className="mt-4 flex justify-between items-center">
                            <div
                                className="cursor-pointer hover:bg-gray-50"
                                onClick={() => setCancelModalOpen(false)}
                            >
                                Close
                            </div>
                            <Button
                                type="cta"
                                className="bg-red-500 hover:bg-red-600"
                                onClick={handleSubmitCancellation}
                            >
                                Cancel line items
                            </Button>
                        </div>
                    </>
                </Modal>
            )}

            {actionLogModelOpen && (
                <Modal
                    title="Action Log"
                    onClose={() => setActionLogModelOpen(false)}
                >
                    <>
                        <span className='block mb-2 text-lg'>Postponements:</span>
                        {order.profortoData.events.postponed.length === 0 && (
                            <p className="text-gray-500">
                                No Postponements have been recorded for this order.
                            </p>
                        )}
                        <div className="overflow-y-auto max-h-96">
                            <ol className="relative border-s border-gray-400 ms-3.5 mb-4">
                                {order.profortoData.events.postponed.sort((a, b) => sortByDateDesc(a.occured, b.occured)).map((event, index) => (
                                    <li key={index} className={classNames("ms-8", { 'mb-10': index !== order.profortoData.events.postponed.length - 1 })}>
                                        <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full -start-3.5 ring-8 ring-white">
                                            <FaRegCalendarAlt className='text-gray-500 w-3 h-3' />
                                        </span>
                                        <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-800">
                                            {event.reason}

                                            {index === 0 && (
                                                <span className="bg-primary text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">Latest</span>
                                            )}
                                        </h3>
                                        <h4 className="block mb-3 text-sm font-normal leading-none text-gray-500">
                                            Old date: <b>{formatToDutchDate(event.originalDate)}</b>
                                            <br />
                                            New date: <b>{formatToDutchDate(event.newDate)}</b>
                                        </h4>

                                        <time className="block mb-2 text-sm font-normal leading-none text-gray-500">
                                            {formatToDutchDateTime(event.occured)}
                                        </time>
                                    </li>
                                ))}
                            </ol>
                        </div>

                        <span className='block mb-2 text-lg'>Cancellations:</span>
                        {order.profortoData.events.cancelled.length === 0 && (
                            <p className="text-gray-500">
                                No cancellations have been recorded for this order.
                            </p>
                        )}

                        <div className="overflow-y-auto max-h-96">
                            <ol className="relative border-s border-gray-400 ms-3.5 mb-4 md:mb-5">
                                {order.profortoData.events.cancelled.sort((a, b) => sortByDateDesc(a.occured, b.occured)).map((event, index) => (
                                    <li key={index} className={classNames("ms-8", { 'mb-10': index !== order.profortoData.events.cancelled.length - 1 })}>
                                        <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full -start-3.5 ring-8 ring-white">
                                            <FaRegCalendarAlt className='text-gray-500 w-3 h-3' />
                                        </span>
                                        <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-800">
                                            {event.note}

                                            {index === 0 && (
                                                <span className="bg-primary text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">Latest</span>
                                            )}
                                        </h3>
                                        <h4 className="block mb-3 text-sm font-normal leading-none text-gray-500">
                                            {event.author}
                                        </h4>

                                        <p className="text-gray-900">
                                            {event.items.map((item, itemIndex) => (
                                                <span>
                                                    {itemIndex > 0 && ", "}
                                                    {item.quantity}x {item.sku}
                                                </span>
                                            ))}
                                        </p>

                                        <time className="block mb-3 text-sm font-normal leading-none text-gray-500">
                                            {formatToDutchDateTime(event.occured)}
                                        </time>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </>
                </Modal>
            )}


            <Accordion title={
                <>
                    {renderOrderState(order)}
                    {order.webshopOrderId}
                </>
            }
                openByDefault={!order.shipped}
            >
                <div className="text-sm mb-4">
                    {order.blocked && order.shipperCode !== CANCELLED_STATE && !order.profortoData.isDeleted && (
                        <span className="block italic underline text-red-500 mb-1">Blocked: {order.blockedMessage}</span>
                    )}

                    <table>
                        <tbody>
                            <tr>
                                <td className='pr-1'>
                                    <strong>Shipping Date:</strong>
                                </td>
                                <td>
                                    {formatToDutchDate(order.shipped) ?? "N/A"}
                                </td>
                                <td>
                                    <span className="text-xs"> (Planned: {formatToDutchDate(order.plannedShipmentDate) ?? "N/A"})</span>
                                </td>
                            </tr>
                            <tr>

                            </tr>
                            <tr>
                                <td className='pr-1'>
                                    <strong>Delivery Date:</strong>
                                </td>
                                <td>
                                    {order.deliveryDate ? formatToDutchDate(order.deliveryDate) : "N/A"}
                                </td>
                                <td>
                                    <span className="text-xs"> (Planned: {formatToDutchDate(order.profortoData.calculatedDeliveryDate) ?? "N/A"})</span>
                                </td>
                            </tr>
                            <tr>
                                <td className='pr-1'>
                                    <strong>Orig. Delivery:</strong>
                                </td>
                                <td>
                                    {formatToDutchDate(order.profortoData.initialDeliveryDate) ?? "N/A"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Table>
                    <Thead>
                        <Tr>
                            <Th className="text-right">Qty</Th>
                            <Th>Name</Th>
                            <Th>Sku</Th>
                            <Th>EAN</Th>
                            <Th>Inbound</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {order.lines.length === 0 && (
                            <Tr>
                                <Td colSpan={4}>
                                    No items found
                                </Td>
                            </Tr>
                        )}
                        {order.lines
                            .map((line, index) => (
                                <Tr key={index} className={`hover:bg-gray-50 ${line.webshopOrderLineId ? '' : 'text-blue-800'}`}>
                                    <Td className="w-4 text-right">
                                        {line.orderedQuantity}
                                    </Td>
                                    <Td>
                                        {order.origin === MontaOriginEnum.Personalisation && (
                                            <Tooltip content={displayPersoDetails(salesOrder.items.find(soItem => soItem.parentItemId === Number(line.webshopOrderLineId)) ?? null)}>
                                                <div className="inline-block mr-1 hover:text-black">
                                                    <FaPaintBrush />
                                                </div>
                                            </Tooltip>
                                        )}
                                        {line.description}
                                    </Td>
                                    <Td>{line.sku}</Td>
                                    <Td>{line.ean ?? '-'}</Td>
                                    <Td>{
                                        line.expectedInboundReferences
                                            ?.map((ref, i) =>
                                                <div>
                                                    <Link className="underline" to={PathConstants.InboundForecastGroupDetail(+(ref.split(' / ')[0]))}>{ref}</Link>
                                                    {i < line.expectedInboundReferences.length - 1 && <span>, </span>}
                                                </div>
                                            )
                                    }</Td>
                                </Tr>
                            ))}

                        {order.profortoData.events.cancelled.map(event => (
                            event.items.map((cancelledItem, itemIndex) => (
                                <Tr key={itemIndex} className="text-red-600">
                                    <Td className="w-4 text-right">
                                        {cancelledItem.quantity}
                                    </Td>
                                    <Td>{cancelledItem.description}</Td>
                                    <Td>{cancelledItem.sku}</Td>
                                    <Td>{cancelledItem.ean}</Td>
                                    <Td></Td>
                                </Tr>
                            ))
                        ))}
                    </Tbody>
                </Table>

                <span className="block text-gray-800 text-sm mt-4">
                    <strong>Track & Trace: </strong>
                    {order.trackAndTraceCode && order.trackAndTraceLink
                        ? <a href={order.trackAndTraceLink} target="_blank" rel="noreferrer" className="text-primary hover:underline">
                            {order.trackAndTraceCode}
                        </a>
                        : "N/A"
                    }
                </span>

                <span className="block text-gray-600 text-xs italic mt-4">
                    Created: {formatToDutchDateTime(order.meta.createdAt)}
                </span>
                <span className="block text-gray-600 text-xs italic">
                    Updated: {formatToDutchDateTime(order.meta.updatedAt)}
                </span>

                <div className='flex justify-between'>
                    <div>
                        {canCancelOrder(order) && isAllowed(Permissions.CANCEL_SALES_ORDERS) && (
                            <div className="mt-4 text-red-500 cursor-pointer inline-block hover:underline" onClick={() => setCancelModalOpen(true)}>
                                Cancel
                            </div>
                        )}
                    </div>

                    <div className="flex justify-self-end mt-4 cursor-pointer hover:underline" onClick={() => setActionLogModelOpen(true)}>
                        Action log
                    </div>
                </div>
            </Accordion>
        </>
    )
}

export default MontaOrder;
