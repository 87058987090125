import classNames from "classnames";

type Props = {
    children: React.ReactNode;
    disabled?: boolean;
    loading?: boolean;
    onClick: () => void;
    className?: string;
    size?: 'medium' | 'small';
    type?: 'cta' | 'white' | 'black' | 'red';
}

const Button = ({ children, disabled, loading, onClick, className, size = 'medium', type = 'black' }: Props) => {
    const handleClick = () => {
        if (disabled) return;
        onClick();
    }
    return (
        <button
            disabled={disabled}
            className={classNames("relative font-medium text-sm transition-all focus:outline-none shadow-md ", {
                'hover:shadow-lg': !disabled,
                'cursor-not-allowed opacity-30': disabled,
                'py-2.5 px-5 rounded-lg': size === 'medium',
                'py-1 px-3 rounded-md': size === 'small',
                'bg-primary hover:bg-primary-darker text-white': type === 'cta',
                'bg-white text-gray-700 border border-gray-300': type === 'white',
                'bg-black text-white': type === 'black',
                'bg-red-500 text-white': type === 'red'
            }, className)}
            onClick={handleClick}
        >
            {loading && (
                <svg className="absolute animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            )}
            <div className={classNames("flex justify-center items-center", { 'invisible': loading })}>
                {children}
            </div>
        </button>
    )
}

export default Button;
