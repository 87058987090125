import { Supplier } from "types/supplier";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApiClient } from "Contexts/ApiClientContext";
import moment from "moment";
import { PageHeader, Tabs, Title, Loading, ManualStocksToPurchaseTable, AutomaticStocksToPurchaseTable } from 'Components';
import { getSupplierRoutes } from "./SupplierDetail";

export default function SupplierDetail() {
    let { id } = useParams();
    const [supplierData, setSupplierData] = useState<Supplier | null>(null);

    const apiClient = useApiClient();

    useEffect(() => {
        const fetchSupplier = async () => {
            try {
                const { data } = await apiClient.getSupplierByCode(id ?? '');
                if (data) {
                    setSupplierData(data);
                }
            } catch (error) {
                console.error("Error fetching suppliers:", error);
            }
        };

        fetchSupplier();
    }, [apiClient, id]);

    if (!id || !supplierData) {
        return (
            <Loading />
        );
    }

    return (
        <>
            <PageHeader
                title={supplierData.name}
                metaData={`Updated: ${moment(supplierData.meta.updatedAt).format('DD-MM-YYYY HH:mm')}`}
            />
            <Tabs routes={getSupplierRoutes(id)}>
                <div className="flex justify-between">
                    <div>
                        <Title>Manual requisitions</Title>
                        <p className="mb-6 text-gray-500 italic text-xs">
                            The line items listed below will be purchased in addition to the automatic requisitions.
                        </p>
                    </div>
                </div>
                <ManualStocksToPurchaseTable
                    supplierId={id}
                />

                <div className="border-b border-gray-300 my-8"></div>

                <Title>Expected automatic requisitions</Title>
                <p className="mb-6 text-gray-500 italic text-xs">
                    The line items underneath are based on the current available stock, minimal stock and remaining inbounds.
                    The final purchase items will be determined when the purchase order is created.
                </p>
                <AutomaticStocksToPurchaseTable montaCode={id} />
            </Tabs>
        </>
    );
}
