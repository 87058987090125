import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { usePermissions, Permissions } from 'Contexts'
import { ComponentType } from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ children, permissions }: { children: ComponentType, permissions?: Permissions[] }) => {
   const { isAuthenticated, isLoading } = useAuth0()
   const { isAllowed } = usePermissions()

   const ProtectedComponent = withAuthenticationRequired(children)

   if (isLoading) {
      return <div>Loading...</div>
   }

   

   if (!isAuthenticated || !permissions?.every(permission => isAllowed(permission))) {
      return <Navigate to='/' />
   }

   return <ProtectedComponent />
}

export default ProtectedRoute
