type Props = {
    children: React.ReactNode;
}

const Title = ({ children }: Props) => (
    <>
        <h2 className="text-2xl font-bold tracking-tight mb-4">{children}</h2>
    </>
)

export default Title
