import { useDebouncedCallback } from "use-debounce";
import { useState, useEffect } from 'react'

export type DateRange = {
    startDate: string;
    endDate: string;
};

type Props = {
    id?: string;
    label?: string;
    value: DateRange;
    onChange: (newValue: DateRange) => void;
    onEnter?: () => void;
    className?: string;
    debounce?: number;
}

const DateRangeInput = ({ id, label, value, onChange, onEnter, className, debounce = 10 }: Props) => {
    const [startDate, setStartDate] = useState(value.startDate);
    const [endDate, setEndDate] = useState(value.endDate);

    useEffect(() => {
        setStartDate(value.startDate);
        setEndDate(value.endDate);
    }, [value])

    const debouncedOnChange = useDebouncedCallback(onChange, debounce);

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStartDate = e.target.value;
        let newEndDate = endDate;

        if (!endDate) {
            // If endDate is not set, automatically set it to the startDate
            newEndDate = newStartDate;
            setEndDate(newEndDate);
        }

        const parsedStartDate = new Date(newStartDate);
        const parsedEndDate = new Date(newEndDate);
        if (parsedStartDate > parsedEndDate) {
            return;
        }

        setStartDate(newStartDate);
        debouncedOnChange({ startDate: newStartDate, endDate: newEndDate });
    }

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEndDate = e.target.value;

        const parsedStartDate = new Date(startDate);
        const parsedEndDate = new Date(newEndDate);
        if (parsedEndDate < parsedStartDate) {
            return;
        }

        setEndDate(newEndDate);
        debouncedOnChange({ startDate, endDate: newEndDate });
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
            onEnter();
        }
    }

    return (
        <div className={className}>
            {label && (
                <label className="font-bold">{label}</label>
            )}
            <div className="relative mt-1 flex gap-2 items-center min-w-12 w-full">
                <input
                    id={id ? `${id}-start` : undefined}
                    type="date"
                    className="inline-block py-2 ps-3 pr-2 text-sm text-gray-900 border border-gray-300 w-full rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                    value={startDate}
                    onChange={handleStartDateChange}
                    onKeyDown={onEnter ? handleKeyDown : undefined}
                />
                <span className="mx-2">to</span>
                <input
                    id={id ? `${id}-end` : undefined}
                    type="date"
                    className="inline-block py-2 ps-3 pr-2 text-sm text-gray-900 border border-gray-300 w-full rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                    value={endDate}
                    onChange={handleEndDateChange}
                    onKeyDown={onEnter ? handleKeyDown : undefined}
                />
            </div>
        </div>
    )
}

export default DateRangeInput;
