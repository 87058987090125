export function display(price: number | string, currency = '€') {
    if (typeof price === 'number' || typeof price === 'string') {
        return formatPrice(price, currency);
    }

    return 'Invalid price';
}

function formatPrice(price: number | string, currency: string) {
    return `${currency} ${Number(price).toLocaleString('nl-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}
