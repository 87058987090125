import classNames from "classnames";

export type Option = {
    value: string;
    label: string;
}

type Props = {
    value: string;
    onChange: (newValue: string) => void;
    options: Option[];
    disabled?: boolean;
    className?: string;
}

const Select = ({ value, disabled, onChange, options, className }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(e.target.value)
    }
    return (
        <select
            className={classNames("bg-white border border-gray-300 mt-1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-96 max-w-full cursor-pointer", className)}
            onChange={handleChange}
            disabled={disabled}
            value={value}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>

            ))}
        </select>
    )
}

export default Select;
