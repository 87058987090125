import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react'
import { PrintNodePrintersDto } from 'types/printers'
import { useApiClient } from 'Contexts/ApiClientContext'
import { useMessage } from 'Contexts/MessageContext'
import { useAuth0 } from '@auth0/auth0-react'

interface PrinterProviderProps {
    children: ReactNode
}

const PrinterContext = createContext<{
    availablePrinters: PrintNodePrintersDto[]
    loading: boolean
}>({
    availablePrinters: [],
    loading: true
})

export const usePrinters = () => {
    const context = useContext(PrinterContext)
    if (context === undefined) {
        throw new Error('useApiClient must be used within an ApiClientProvider')
    }
    return context
}

export const PrinterProvider: React.FC<PrinterProviderProps> = ({ children }) => {
    const apiClient = useApiClient();
    const { isAuthenticated } = useAuth0();
    const { handleError } = useMessage();
    const [loading, setLoading] = useState(true)
    const [availablePrinters, setAvailablePrinters] = useState([] as PrintNodePrintersDto[])

    useEffect(() => {
        if (!isAuthenticated || !apiClient || apiClient.isLoading || !handleError) return;
        const fetchAvailablePrinters = async () => {
            const { data } = await apiClient.getAvailablePrinters();
            if (data) {
                setAvailablePrinters(data);
            }
            setLoading(false);
        };
        fetchAvailablePrinters();
    }, [apiClient, handleError, isAuthenticated]);

    return (
        <PrinterContext.Provider value={{
            loading,
            availablePrinters
        }}>
            {children}
        </PrinterContext.Provider>
    )
}
