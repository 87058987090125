import { useEffect, useState, useCallback, useRef } from 'react';
import { useApiClient } from 'Contexts/ApiClientContext';
import { Loading, NumberInput, Button, TextInput, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from 'Components';
import { Stock, RequisitionStock, RequisitionInput } from 'types/stock';
import { MdDelete } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import classNames from 'classnames';
import AddBySearch from './AddBySearch';
import AddByImport from './AddByImport';
import { useMessage, usePermissions } from "Contexts";
import Modal from "Components/Library/Modal";
import DateInput from 'Components/Library/Form/DateInput';

type Props = {
    supplierId: string
    className?: string
}

type AddByMethod = 'Search' | 'Import'

const AutomaticStockToPurchase = ({ supplierId, className }: Props) => {
    const [manualRequisitions, setManualRequisitions] = useState<RequisitionStock[] | null>(null);
    const [stocks, setStocks] = useState<Stock[] | null>(null);
    const [actionLoading, setActionLoading] = useState(false)
    const [addByMethod, setAddByMethod] = useState<AddByMethod>()

    const [manualPoModalOpen, setManualPoModalOpen] = useState(false);
    const [submitMethod, setSubmitMethod] = useState<'default' | 'custom_email' | 'skip'>('default');
    const [customEmail, setCustomEmail] = useState('');
    const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);

    const apiClient = useApiClient();

    const { isAllowed, Permissions } = usePermissions();

    const { handleError } = useMessage();

    const fetchManualStockToPurchase = useCallback(async () => {
        try {
            const { data } = await apiClient.getRequisitionsBySupplierCode(supplierId ?? '');
            if (data) {
                setManualRequisitions(data);
            }
        } catch (error) {
            console.error("Error fetching manual requisitions:", error);
            setManualRequisitions([]);
        }
    }, [apiClient, supplierId]);

    useEffect(() => {
        fetchManualStockToPurchase()
    }, [fetchManualStockToPurchase]);

    const stocksFetched = useRef(false);
    useEffect(() => {
        const fetchStocks = async () => {
            // Stocks only has to be fetched once since the ENTIRE table is requested...
            if (stocksFetched.current) return;
            stocksFetched.current = true;

            try {
                const { data } = await apiClient.getStocksBySupplierCode(supplierId ?? '');
                if (data) {
                    setStocks(data);
                }
            } catch (error) {
                console.error("Error fetching stocks:", error);
            }
        };

        fetchStocks();
    }, [apiClient, stocks, supplierId]);

    const handleQuantityChange = async (mongoId: string, quantity: number) => {
        // TODO: this should check if reference is filled and check if already exists
        // Update or add
        if (!supplierId) return;
        setActionLoading(true);

        const requisition = manualRequisitions?.find(requisition => requisition.mongoId === mongoId);
        if (!requisition) return;

        const updatedRequisition = { ...requisition, quantity };

        const { data } = await apiClient.updateRequisitionQuantityBySupplierCode(supplierId, updatedRequisition);
        if (data) {
            setManualRequisitions(data);
        }
        setActionLoading(false);
    }


    if (manualRequisitions === null) return (
        <Loading />
    );


    const handleRemoveItem = async (requisitionId: string) => {
        if (!supplierId) return;

        setActionLoading(true);
        const { data } = await apiClient.removeRequisitionsBySupplierCode(supplierId, [requisitionId]);
        if (data) {
            setManualRequisitions(data);
        }
        setActionLoading(false);
    }

    const handleAddItem = async (requisition: RequisitionInput) => {
        handleAddItems([requisition])
    }

    const handleAddItems = async (requisitions: RequisitionInput[]) => {
        setActionLoading(true);
        const { data } = await apiClient.addRequisitionsBySupplierCode(supplierId, requisitions);
        if (data && data.length) {
            setManualRequisitions(data);
        }
        setActionLoading(false);
    }

    const _manualRequisitions = manualRequisitions?.map(requisition => {
        const correspondingStock = stocks?.find(stock => stock.product.sku === requisition.product.sku);
        return {
            ...requisition,
            name: correspondingStock?.product.name ?? requisition.product.name,
            ean: correspondingStock?.product.ean ?? requisition.product.ean,
            step: correspondingStock?.amounts.step ?? 1,
            minimum: correspondingStock?.amounts.minimum ?? 0,
            available: correspondingStock?.amounts.available ?? 0,
            forecasted: correspondingStock?.amounts.forecasted ?? 0,
            reserved: correspondingStock?.amounts.reserved ?? 0
        }
    })

    const handleCreateManualPO = async () => {
        try {
            if (!supplierId) {
                handleError('Error creating separate purchase order: Supplier ID not found');
                return;
            }
            const requisitionIds = _manualRequisitions?.map(requisition => requisition.mongoId);
            const { data } = await apiClient.createManualPurchaseOrder(
                supplierId,
                requisitionIds,
                submitMethod === 'default',
                submitMethod === 'custom_email'
                    ? customEmail
                    : undefined,
                deliveryDate
            );
            await fetchManualStockToPurchase();
            if (data) {
                setManualPoModalOpen(false);
            }
        } catch (error) {
            console.error('Error creating separate purchase order:', error);
        }
    }

    const totalPrice = manualRequisitions.reduce((acc, stock) => acc + stock.quantity * stock.stock.price.purchasePrice.value, 0);

    return (
        <div className={className}>
            {manualRequisitions.length === 0 ? (
                <p className="mb-2">No manual requisitions have been added, you can add them below.</p>
            ) : (
                <>
                    {manualPoModalOpen && (
                        <Modal
                            title="Create Manual Purchase Order"
                            onClose={() => setManualPoModalOpen(false)}
                        >
                            <>
                                <b className="px-2">Please select how we should submit the PO:</b>
                                <div className="ml-2 mt-1">
                                    <label className="block cursor-pointer">
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            value="default"
                                            onChange={() => setSubmitMethod('default')}
                                            checked={submitMethod === 'default'}
                                        />
                                        Use default integration
                                    </label>
                                    <label className="block cursor-pointer my-1">
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            value="custom_email"
                                            onChange={() => setSubmitMethod('custom_email')}
                                            checked={submitMethod === 'custom_email'}
                                        />
                                        Send to custom email
                                    </label>
                                    {submitMethod === 'custom_email' && (
                                        <TextInput
                                            className="mb-2 pl-6"
                                            label="Email address"
                                            value={customEmail ?? ''}
                                            onChange={setCustomEmail}
                                        />
                                    )}
                                    <label className="block cursor-pointer my-1">
                                        <input
                                            type="radio"
                                            className="mr-2"
                                            value="skip"
                                            onChange={() => setSubmitMethod('skip')}
                                            checked={submitMethod === 'skip'}
                                        />
                                        Don't send
                                    </label>
                                    <div className="pr-1 py-3">
                                        <DateInput
                                            label="Override Delivery Date"
                                            value={''}
                                            onChange={value => setDeliveryDate(new Date(value))}
                                        />
                                    </div>
                                    <div className="flex justify-between items-center mt-4 my-1">
                                        <div
                                            className="cursor-pointer hover:underline"
                                            onClick={() => setManualPoModalOpen(false)}
                                        >
                                            Cancel
                                        </div>
                                        <Button
                                            onClick={handleCreateManualPO}
                                            disabled={submitMethod === 'custom_email' && !customEmail}
                                            type="cta"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </Modal>
                    )}

                    <Table className="mb-6">
                        <Thead>
                            <Tr>
                                <Th>Product name</Th>
                                <Th>Sku</Th>
                                <Th>Ean</Th>
                                <Th className="text-right">Available</Th>
                                <Th className="text-right">Minimum</Th>
                                <Th className="text-right">Reserved</Th>
                                <Th className="text-right">Forecasted</Th>
                                <Th className="text-right">Step</Th>
                                <Th className="w-32">To purchase</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {_manualRequisitions?.map((requisition, index) => {
                                return (
                                    <Tr key={requisition.mongoId}>
                                        <Td>{requisition.product.name}</Td>
                                        <Td>{requisition.product.sku}</Td>
                                        <Td>{requisition.product.ean}</Td>
                                        <Td className="text-right">{requisition.available}</Td>
                                        <Td className="text-right">{requisition.minimum}</Td>
                                        <Td className="text-right">{requisition.reserved}</Td>
                                        <Td className="text-right">{requisition.forecasted}</Td>
                                        <Td className="text-right">{requisition.step}</Td>
                                        <Td className="flex justify-between items-center gap-4">
                                            {isAllowed(Permissions.UPDATE_REQUISITIONS) ? (
                                                <>
                                                    <NumberInput
                                                        min={requisition.step}
                                                        step={requisition.step}
                                                        value={requisition.quantity}
                                                        onChange={(value) => handleQuantityChange(requisition.mongoId, value)}
                                                        debounce={500}
                                                    />
                                                    <MdDelete
                                                        className={classNames("h-6 w-6 text-gray-500 shrink-0 hover:text-gray-700 transition-all", {
                                                            'opacity-30 cursor-not-allowed': actionLoading,
                                                            'cursor-pointer': !actionLoading
                                                        })}
                                                        onClick={() => handleRemoveItem(requisition.mongoId)}
                                                    />
                                                </>
                                            ) : requisition.quantity}
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Td colSpan={10} className="text-right">
                                    Total cost: €&thinsp;{totalPrice.toFixed(2)}
                                </Td>
                            </Tr>
                        </Tfoot>
                    </Table>

                    {isAllowed(Permissions.CREATE_PURCHASE_ORDERS) && (
                        <Button
                            className="mb-8"
                            onClick={() => setManualPoModalOpen(true)}
                            size="small"
                            type="white"
                        >
                            Create Manual PO
                        </Button>
                    )}
                </>
            )}
            {isAllowed(Permissions.UPDATE_REQUISITIONS) && (
                <>
                    <div className="mb-4">
                        <p className="font-bold mb-2">Add new requisition by</p>
                        <div className="flex gap-2">
                            <Button
                                onClick={() => setAddByMethod('Search')}
                                type="white"
                                size='small'
                            >
                                Search
                            </Button>
                            <Button
                                onClick={() => setAddByMethod('Import')}
                                type="white"
                                size='small'
                            >
                                Import
                            </Button>
                        </div>
                    </div>

                    <div className={classNames("relative", {
                        'border border-gray-200 rounded-lg shadow-md ': addByMethod
                    })}>
                        {addByMethod && (
                            <IoMdClose
                                className="absolute right-2 top-2 h-8 w-8 cursor-pointer text-gray-400"
                                onClick={() => setAddByMethod(undefined)}
                            />
                        )}
                        {addByMethod === 'Search' && (
                            <AddBySearch
                                supplierId={supplierId}
                                stocks={stocks}
                                handleAddItem={handleAddItem}
                                actionPending={actionLoading}
                            />
                        )}
                        {addByMethod === 'Import' && (
                            <AddByImport
                                supplierId={supplierId}
                                stocks={stocks}
                                handleAddItems={handleAddItems}
                                actionPending={actionLoading}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default AutomaticStockToPurchase;
