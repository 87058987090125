type Props = {
    children: React.ReactNode;
}

const Title = ({ children }: Props) => (
    <>
        <p className="mt-4 block font-bold tracking-tight text-gray-900 first:mt-0">{children}</p>
        <hr className="mt-1 mb-2" />
    </>
)

export default Title
