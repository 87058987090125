import classNames from "classnames";

type Props = {
    label?: React.ReactNode;
    value: boolean;
    onChange: (newValue: boolean) => void;
    className?: string
}
const Toggle = ({ label, value, onChange, className }: Props) => {

    const handleChange = (newValue: boolean) => {
        onChange(newValue);
    }

    return (
        <div className={classNames(className)}>
            <label className="flex items-center cursor-pointer">
                <input type="checkbox" checked={value} onChange={() => handleChange(!value)} className="sr-only peer" />
                <div className="relative w-11 h-6 bg-gray-300 shadow-inner peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                {label && (
                    <span className="ms-3 text-sm text-gray-900">{label}</span>
                )}
            </label>
        </div>
    )
}

export default Toggle;
