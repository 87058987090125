import { useDebouncedCallback } from "use-debounce";
import { useState, useEffect } from 'react'

type Props = {
    id?: string;
    label?: string;
    value: string;
    onChange: (newValue: string) => void;
    onEnter?: () => void;
    className?: string;
    debounce?: number;
}
const DateInput = ({ id, label, value, onChange, onEnter, className, debounce = 10 }: Props) => {
    const [val, setVal] = useState(value);

    useEffect(() => {
        setVal(value)
    }, [value])

    const _onChange = useDebouncedCallback(onChange, debounce)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setVal(newValue);
        _onChange(newValue);
    }

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
            onEnter();
        }
    }

    return (
        <div className={className}>
            {label && (
                <label className="font-bold">{label}</label>
            )}
            <div className="relative mt-1 flex gap-2 items-center min-w-12 w-full">
                <input
                    id={id}
                    type="date"
                    className="inline-block py-2 ps-3 pr-2 text-sm text-gray-900 border border-gray-300 w-full rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                    value={val}
                    onChange={handleChange}
                    onKeyDown={onEnter ? keyDown : undefined}
                />
            </div>
        </div>
    )
}

export default DateInput
