import classNames from 'classnames';
import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

type Props = {
    title: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    classNameHeader?: string;
    openByDefault?: boolean;
}

const Accordion = ({ children, title, className, classNameHeader, openByDefault = false }: Props) => {

    const [isOpen, setIsOpen] = useState(openByDefault);

    return (
        <div className={classNames(className)}>
            <h2>
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={classNames("flex items-center justify-between bg-gray-200 transition-all w-full p-6 py-4 font-medium rtl:text-right text-gray-900 border border-gray-200 rounded-t-lg focus:ring-2 focus:ring-gray-200 cursor-pointer hover:bg-gray-300 gap-3", classNameHeader, {
                        'border-b-0': isOpen,
                        'rounded-lg': !isOpen
                    })}>
                    <span className="flex font-bold tracking-tight text-gray-900">{title}</span>
                    <IoIosArrowDown className={classNames("transition-transform duration-150", isOpen ? 'rotate-180' : '')} />
                </div>
            </h2>
            <div className={classNames(isOpen ? 'block' : 'hidden')}>
                <div className="p-6 bg-gray-50 rounded-b-lg border border-t-0 border-gray-200">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Accordion
