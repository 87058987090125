import { useState, useEffect, ReactNode } from 'react';

interface ShakeButtonProps {
    children: ReactNode;
    onShake: () => void;
}

const ShakeButton = ({ children, onShake }: ShakeButtonProps) => {
    const [clickCount, setClickCount] = useState(0);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [isShaking, setIsShaking] = useState(false);

    const handleClick = () => {
        const now = Date.now();
        if (now - lastClickTime < 500) { // 500ms threshold for "relatively fast"
            setClickCount(prevCount => prevCount + 1);
        } else {
            setClickCount(1);
        }
        setLastClickTime(now);
    };

    useEffect(() => {
        if (clickCount >= 2) {
            setIsShaking(true);
            onShake(); // Call the onShake callback
            setTimeout(() => setIsShaking(false), 500); // Shake duration
            setClickCount(0); // Reset click count after shaking
        }
    }, [clickCount, onShake]);

    return (
        <button onClick={handleClick} className={`${isShaking ? 'animate-shake' : ''}`}>
            {children}
        </button>
    );
};

export default ShakeButton;
