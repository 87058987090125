import { useRouteError } from "react-router-dom";
import { PageHeader } from "Components";


export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <PageHeader title="Oops!" />
      <p>This page is lost between all our shelves and forklifts, searching for its digital warehouse home!</p>
    </>
  );
}
