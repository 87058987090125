export const euro = Intl.NumberFormat('en-NL', {
    style: 'currency',
    currency: 'EUR',
});

export const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
};

const timeHHmmssRegex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])(:([0-5][0-9]))?$/;

export const isValidTimeHHmmss = (value: string): boolean => {
    // Regular expression to match HH:mm or HH:mm:ss format
    const match = value.match(timeHHmmssRegex);
    return !!match;
}

export const formatTimeWithSecondsToHoursMinutes = (value: string) => {
    const match = value.match(timeHHmmssRegex);
    if (match) {
        // Format and append :00 for seconds, ignoring actual seconds input
        return `${match[1].padStart(2, '0')}:${match[2].padStart(2, '0')}`;
    }

    // Return empty string if input is invalid
    return value;
};

export const isEmpty = (str: string | null | undefined): boolean => {
    return (!str || str.length === 0);
}

export const TryParseFloat = (str: string | null | undefined, out: { result?: number }): boolean => {
    // Check falsey
    if (!str) return false;
    // Check float parse
    const num = parseFloat(str);
    if (isNaN(num)) return false;
    // Handle result
    out.result = num;
    return true;
}

export const TryParseInt = (str: string | null | undefined, out: { result?: number }): boolean => {
    // Check falsey
    if (!str) return false
    // Check int parse
    const num = parseInt(str);
    if (isNaN(num)) return false;
    // Handle result
    out.result = num;
    return true;
}
