import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {
  RouterProvider,
} from "react-router-dom"
import router from "./router"
import { Auth0Provider } from '@auth0/auth0-react'
import { MessageProvider } from './Contexts/MessageContext'
import { ApiClientProvider } from 'Contexts/ApiClientContext'
import { PermissionsProvider, Permissions } from 'Contexts/PermissionsContext'

import packageJson from '../package.json'
import { PrinterProvider } from 'Contexts/PrinterContext'
import { PageProvider } from 'Contexts/PageContext'

declare global {
  interface Window {
    PROCUREMENT_APP_VERSION: string
  }
}

if (window && packageJson) {
  window.PROCUREMENT_APP_VERSION = packageJson.version
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="proforto.eu.auth0.com"
      clientId="OoRwNBcRBWneKaJLWmMmgBS8mJnVnbnC"
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: `openid profile email offline_access ${Object.values(Permissions).join(' ')}`,
        audience: 'procurement-api',
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
    >
      <PermissionsProvider>
        <MessageProvider>
          <ApiClientProvider>
            <PrinterProvider>
              <PageProvider>
                <RouterProvider router={router} />
              </PageProvider>
            </PrinterProvider>
          </ApiClientProvider>
        </MessageProvider>
      </PermissionsProvider>
    </Auth0Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
