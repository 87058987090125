import { useEffect, useRef, useState } from "react";
import { useApiClient } from 'Contexts/ApiClientContext';
import { Pagination } from "utils/apiClient";
import { Button, Loading, PageHeader, Pager, Select, TextInput } from 'Components';
import Modal from "Components/Library/Modal";
import DateInput from "Components/Library/Form/DateInput";
import { SalesOrder, SalesOrderSearchCriteria, StoreEnum } from "types/sales-order";
import SalesOrderTable from "Components/SalesOrders/SalesOrderTable";
import Toggle from "Components/Library/Form/Toggle";
import { isEmpty } from "utils";
import { useDebounce } from 'use-debounce';
import { PersoSelectEnum, convertPersoSelectEnumToLabel, convertStoreEnumToLabel } from "utils/enums";
import { useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";

interface SearchCriteria {
    mageIdsearchQuery: string;
    selectedStore: StoreEnum;
    hasPersonalisation: PersoSelectEnum;
    searchQueryGeneral: string;
    searchQuerySku: string;
    isNeedsAttention: boolean;
}

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 30;

export default function SalesOrderList() {
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const location = useLocation();
    // Retrieve initial search parameters from sessionStorage or default values
    const initialSearchParams = (): SearchCriteria => {
        const storedParams = sessionStorage.getItem('searchParamsSales');
        return storedParams ? JSON.parse(storedParams) : {
            mageIdsearchQuery: "",
            selectedStore: StoreEnum.All,
            hasPersonalisation: PersoSelectEnum.Any_Perso,
            searchQueryGeneral: "",
            searchQuerySku: "",
            isNeedsAttention: location.search.includes('needs-attention=true')
        };
    };

    const [searchParams, setSearchParams] = useState<SearchCriteria>(initialSearchParams);
    const [debouncedSearchParams] = useDebounce(searchParams, 300);
    const previousSearchCriteria = useRef<SalesOrderSearchCriteria | null>(null);

    const [salesOrders, setSalesOrders] = useState(null as SalesOrder[] | null)
    const [pagination, setPagination] = useState<Pagination | null>(null)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date()
    });

    const handleSearchParamChange = (param: keyof SearchCriteria, value: any) => {
        if (param === "isNeedsAttention") {
            navigate({
                search: value
                    ? `${location.search ? location.search + '&' : '?'}needs-attention=true`
                    : location.search.replace('?needs-attention=true', '').replace('needs-attention=true', '')
            }, { replace: true });
        }

        setSearchParams(prevState => ({
            ...prevState,
            [param]: value
        }));
    };
    const handleClearSearchParams = () => {
        sessionStorage.removeItem('searchParamsSales');
        setSearchParams(initialSearchParams);
    }

    // Store searchParams in sessionStorage whenever they change
    useEffect(() => {
        sessionStorage.setItem('searchParamsSales', JSON.stringify(debouncedSearchParams));
    }, [debouncedSearchParams]);

    useEffect(() => {
        const fetchPurchaseOrders = async () => {
            try {
                const searchCriteria: SalesOrderSearchCriteria = {
                    mageOrderId: isEmpty(debouncedSearchParams.mageIdsearchQuery)
                        ? null : debouncedSearchParams.mageIdsearchQuery,
                    criteria: isEmpty(debouncedSearchParams.searchQueryGeneral)
                        ? null : debouncedSearchParams.searchQueryGeneral,
                    sku: isEmpty(debouncedSearchParams.searchQuerySku)
                        ? null : debouncedSearchParams.searchQuerySku,
                    storeEnum: debouncedSearchParams.selectedStore === StoreEnum.All
                        ? null : debouncedSearchParams.selectedStore,
                    hasPersonalisation: debouncedSearchParams.hasPersonalisation === PersoSelectEnum.Any_Perso
                        ? null : debouncedSearchParams.hasPersonalisation === PersoSelectEnum.With_Perso,
                    isNeedsAttention: debouncedSearchParams.isNeedsAttention,
                    page: pagination?.page ?? DEFAULT_PAGE,
                }
                if (searchCriteria.mageOrderId === previousSearchCriteria.current?.mageOrderId
                    && searchCriteria.criteria === previousSearchCriteria.current?.criteria
                    && searchCriteria.sku === previousSearchCriteria.current?.sku
                    && searchCriteria.storeEnum === previousSearchCriteria.current?.storeEnum
                    && searchCriteria.hasPersonalisation === previousSearchCriteria.current?.hasPersonalisation
                    && searchCriteria.isNeedsAttention === previousSearchCriteria.current?.isNeedsAttention
                    && searchCriteria.page === previousSearchCriteria.current?.page) {
                    // No need to fetch again if the search criteria hasn't changed
                    return;
                }
                previousSearchCriteria.current = searchCriteria;

                const { data } = await apiClient.getSalesOrders(
                    searchCriteria,
                    pagination?.page ?? DEFAULT_PAGE,
                    pagination?.pageSize ?? DEFAULT_PAGE_SIZE
                );
                if (data) {
                    setSalesOrders(data.data);
                    setPagination(data.pagination);
                }
            } catch (error) {
                console.error("Error fetching suppliers:", error);
            }
        };

        fetchPurchaseOrders();
    }, [apiClient, pagination?.page, pagination?.pageSize, debouncedSearchParams]);

    const handleNextPageClick = () => {
        if (!pagination?.hasNextPage) return;
        setPagination({ ...pagination, page: pagination?.page + 1 } as Pagination);
    };
    const handlePreviousPageClick = () => {
        if (!pagination?.hasPreviousPage) return;
        setPagination({ ...pagination, page: pagination.page - 1 } as Pagination);
    };

    const handlePersoOptionChange = (newValue: string) => {
        handleSearchParamChange("hasPersonalisation", newValue as PersoSelectEnum)
    };

    const handleOrderNumberChange = (newValue: string) => {
        const search = newValue.replace(/-/g, '');

        handleSearchParamChange("mageIdsearchQuery", search)
    }

    const storeEntries = Object.entries(StoreEnum)
        .filter(([_key, value]) => !isNaN(Number(value)))
        .map(([_label, value]) => ({ value: value.toString(), label: convertStoreEnumToLabel(value as StoreEnum) }));

    const persoEntrries = Object.entries(PersoSelectEnum)
        .map(([_label, value]) => ({ value: value.toString(), label: convertPersoSelectEnumToLabel(value) }));



    const openDownloadPostponedFulfillmentsModal = () => {
        setIsModalOpen(true);
    };

    // Placeholder function for handling date range selection from the modal
    const handleDateRangeConfirm = async (_startDate: any, _endDate: any) => {
        setIsModalOpen(false); // Close the modal
        // Assuming setDateRange is meant to update the state for selectedDateRange
        setSelectedDateRange({ startDate: selectedDateRange.startDate, endDate: selectedDateRange.endDate });

        try {
            const response = await apiClient.downloadPostponedFulfillments(selectedDateRange.startDate.toISOString().substring(0, 10), selectedDateRange.endDate.toISOString().substring(0, 10));
            console.log(response);
        } catch (error) {
            console.error("Failed to download postponed fulfillments:", error);
        }
    };

    if (salesOrders === null) return (
        <Loading />
    );

    return (
        <>
            <PageHeader
                title="Sales Orders"
                toolbar={(
                    <Button onClick={openDownloadPostponedFulfillmentsModal} type="black">
                        Download Initial Dates
                    </Button>
                )}
            />

            {isModalOpen && (
                <Modal title="Select Date Range" onClose={() => setIsModalOpen(false)}>
                    <div className="flex flex-col items-center justify-center p-4 gap-4">
                        <div className="flex flex-row justify-between w-full">
                            <DateInput
                                className="w-1/2 mr-2"
                                id="startDate"
                                label="Start Date"
                                value={selectedDateRange.startDate ? selectedDateRange.startDate.toISOString().substring(0, 10) : ''}
                                onChange={(date) => setSelectedDateRange(prev => ({ ...prev, startDate: new Date(date) }))}
                            />

                            <DateInput
                                className="w-1/2"
                                id="endDate"
                                label="End Date"
                                value={selectedDateRange.endDate ? selectedDateRange.endDate.toISOString().substring(0, 10) : ''}
                                onChange={(date) => setSelectedDateRange(prev => ({ ...prev, endDate: new Date(date) }))}
                            />
                        </div>
                        <Button onClick={() => handleDateRangeConfirm(selectedDateRange.startDate, selectedDateRange.endDate)}>
                            Submit
                        </Button>
                    </div>
                </Modal>
            )}

            <div className="flex items-center mb-4 gap-x-3">
                <TextInput
                    placeHolder="Search by order ID"
                    className="w-40"
                    value={searchParams.mageIdsearchQuery}
                    onChange={(newValue) => handleOrderNumberChange(newValue)}
                    withClearButton
                />
                <TextInput
                    placeHolder="Search for zipcode, email or name"
                    className="w-64"
                    value={searchParams.searchQueryGeneral}
                    onChange={(newValue) => handleSearchParamChange("searchQueryGeneral", newValue)}
                    withClearButton
                />
                <TextInput
                    placeHolder="Search by sku"
                    className="w-60"
                    value={searchParams.searchQuerySku}
                    onChange={(newValue) => handleSearchParamChange("searchQuerySku", newValue)}
                    withClearButton
                />
                <Select
                    className="!w-36"
                    value={searchParams.selectedStore.toString()}
                    onChange={(newValue) => handleSearchParamChange("selectedStore", Number(newValue) as StoreEnum)}
                    options={storeEntries}
                />
                <Select
                    className="!w-36"
                    value={searchParams.hasPersonalisation.toString()}
                    onChange={(newValue) => handlePersoOptionChange(newValue)}
                    options={persoEntrries}
                />
                <Button type="white" className="mt-1 w-5 h-10 shadow-none" onClick={handleClearSearchParams}>
                    <IoClose className="-my-2 -mx-2" />
                </Button>
                <Toggle
                    className="ml-auto"
                    label={"Needs Attention"}
                    value={searchParams.isNeedsAttention}
                    onChange={(newValue) => handleSearchParamChange("isNeedsAttention", newValue)}
                />
            </div>

            <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                <SalesOrderTable salesOrders={salesOrders} />
            </div>
            {pagination && (
                <Pager
                    currentPage={pagination.page}
                    pageSize={pagination.pageSize}
                    totalItems={pagination.totalItems}
                    handleNextPageClick={handleNextPageClick}
                    handlePreviousPageClick={handlePreviousPageClick}
                />
            )}
        </>
    );
}
