import { useEffect } from 'react';
import { usePageContext, DEFAULT_PAGE_TITLE } from '../Contexts/PageContext';

export const useDocumentTitle = (title?: string) => {
  const { setTitle } = usePageContext();

  useEffect(() => {
    setTitle(title ?? DEFAULT_PAGE_TITLE);
  }, [title, setTitle]);
};
