import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useApiClient } from 'Contexts/ApiClientContext';
import { usePermissions } from "Contexts";
import moment from "moment";
import { Button, Card, Loading, PageHeader, SectionTitle, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from 'Components';
import { PurchaseOrder, PurchaseOrderMethodEnum } from "types/purchase-order";
import { display } from "utils/pricing";
import Modal from "Components/Library/Modal";
import MultiValueInput, { InputType } from "Components/Library/Form/MultiValueInput";
import ErrorEvents from "Components/ErrorEvents";

export default function PurchaseOrderDetail() {

    let { id } = useParams();
    const apiClient = useApiClient();
    const { isAllowed, Permissions } = usePermissions()

    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder | null>(null)
    const [showEmailDialog, setShowEmailDialog] = useState(false);
    const [showErrorLog, setShowErrorLog] = useState(false);

    const [mancoDeliveryEmails, setMancoDeliveryEmails] = useState<string[]>([]);

    useEffect(() => {
        const fetchPurchaseOrder = async () => {
            if (!id) return;
            try {
                const { data } = await apiClient.getPurchaseOrderById(id);
                if (data) {
                    setPurchaseOrder(data);
                }
            } catch (error) {
                console.error("Error fetching purchase order:", error);
            }
        };

        fetchPurchaseOrder();
    }, [apiClient, id]);

    if (purchaseOrder === null) return (
        <Loading />
    )

    const handleShowMailClicked = () => {
        setShowEmailDialog(true);
    };
    const handleCloseMailDialog = () => {
        setShowEmailDialog(false);
    }

    const handleErrorLogClicked = () => {
        setShowErrorLog(true);
    };
    const handleErrorLogClosed = () => {
        setShowErrorLog(false);
    }

    const handleMailPurchaseOrder = () => {
        if (mancoDeliveryEmails.length === 0) return;

        apiClient.mailPurchaseOrder(purchaseOrder.purchaseOrderNumber, mancoDeliveryEmails)

        setShowEmailDialog(false);
    };

    const handleMarkAsSubmitted = async () => {
        var updateResult = await apiClient.markPurchaseOrderAsSubmitted(purchaseOrder.purchaseOrderNumber);
        if (updateResult) {
            setPurchaseOrder(updateResult);
        }
    };


    const handleResolveErrorItem = async (errorId: string) => {
        if (!purchaseOrder) return;

        var updatedEvent = await apiClient.resolvePurchaseOrderErrorEvent(purchaseOrder.purchaseOrderNumber, errorId);
        if (!updatedEvent || updatedEvent == null) return;

        const eventIndex = purchaseOrder.errorEvents.findIndex(event => event.id === updatedEvent!.id);

        if (eventIndex === -1) return;

        const updatedErrorEvents = [...purchaseOrder.errorEvents];
        updatedErrorEvents[eventIndex] = updatedEvent;

        // Use update the purchaseOrder state with the new errorEvents array
        setPurchaseOrder({ ...purchaseOrder, errorEvents: updatedErrorEvents });
    };

    const handleResolveAllErrors = async () => {
        if (!purchaseOrder) return;

        var updatedPurchaseOrder = await apiClient.resolveAllPurchaseOrderErrorEvents(purchaseOrder.purchaseOrderNumber);
        if (!updatedPurchaseOrder) return;
        console.log('updated:', updatedPurchaseOrder.errorEvents);

        setPurchaseOrder({ ...purchaseOrder, errorEvents: updatedPurchaseOrder.errorEvents });
        setShowErrorLog(false);
    }

    const amountOfErrors = purchaseOrder.errorEvents.filter(e => !e.isResolved).length;

    return (
        <>
            <div className="flex justify-between items-center">
                <PageHeader
                    title={purchaseOrder.purchaseOrderNumber.toString()}
                    toolbar={<>
                        {isAllowed(Permissions.MARK_AS_SUBMITTED_PURCHASE_ORDERS) && !purchaseOrder.submitted && (
                            <Button onClick={handleMarkAsSubmitted} className="mr-2">
                                Mark as Submitted
                            </Button>
                        )}
                        <Button onClick={handleErrorLogClicked} type="white">
                            Error Log {amountOfErrors > 0 && `(${amountOfErrors})`}
                        </Button>
                        <Button onClick={handleShowMailClicked}>
                            Mail
                        </Button>
                    </>}
                    metaData={`Created: ${moment(purchaseOrder.meta.createdAt).format('DD-MM-YYYY HH:mm')}`}
                />
            </div>

            <div className="grid md:grid-cols-2">
                <Card className="w-96">
                    <SectionTitle>Supplier</SectionTitle>
                    <span className="block">{purchaseOrder.supplier.name}</span>
                    <SectionTitle>Details</SectionTitle>
                    <span className="block">Method: {PurchaseOrderMethodEnum[purchaseOrder.purchase.method]}</span>
                    <span className="block">Manual: {purchaseOrder.purchase.isManual ? 'Yes' : 'No'}</span>
                    <span className="block">Expected Delivery Date: {purchaseOrder.expectedDeliveryDate}</span>
                </Card>
            </div>


            {showEmailDialog && (
                <Modal title="Mail Purchase Order to" onClose={handleCloseMailDialog}>
                    <div className="w-96">
                        <MultiValueInput
                            values={mancoDeliveryEmails ?? []}
                            setValues={(e) => {
                                setMancoDeliveryEmails(e);
                            }}
                            inputType={InputType.Email}
                        />

                        <div className="mt-2">
                            <Button onClick={handleMailPurchaseOrder} type='cta'>
                                Send through email
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}

            {showErrorLog && (
                <Modal title="Error Log" onClose={handleErrorLogClosed} showBackdrop closeWithEscape>
                    <ErrorEvents
                        errorEvents={purchaseOrder.errorEvents || []}
                        allowedToResolve={isAllowed(Permissions.RESOLVE_ERROR_EVENT_PURCHASE_ORDERS)}
                        resolveErrorEvent={handleResolveErrorItem}
                        resolveAllErrorEvents={handleResolveAllErrors}
                    />
                </Modal>
            )}

            <SectionTitle>Items</SectionTitle>
            <div className="overflow-x-auto">
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Sku</Th>
                            <Th>EAN</Th>
                            <Th className="text-right">
                                Qty
                            </Th>
                            <Th className="text-right">
                                Purchase Amount
                            </Th>
                            <Th className="text-right">
                                Reference
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {purchaseOrder.items.length === 0 && (
                            <Tr>
                                <Td colSpan={6}>
                                    No items found
                                </Td>
                            </Tr>
                        )}
                        {purchaseOrder.items.map((line, index) => (
                            <Tr key={index}>
                                <Td>{line.product.name}</Td>
                                <Td>{line.product.sku}</Td>
                                <Td>{line.product.ean ?? "-"}</Td>
                                <Td className="text-right">
                                    {line.quantity}
                                </Td>
                                <Td className="text-right">
                                    {display(line.price.purchasePrice.value)}
                                </Td>
                                <Td className="text-right">
                                    <span
                                    // className="text-primary hover:underline cursor-pointer"
                                    // onClick={() => navigate(`/sales-orders/${ref}`)} // TODO: should navigate to sales order
                                    >
                                        {line.reference}
                                    </span>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>

                    <Tfoot>
                        <Tr>
                            <Td className="text-right" colSpan={6}>
                                <strong>Total purchase amount:</strong> {display(purchaseOrder.price.totalPurchasePrice.value)}
                            </Td>
                        </Tr>
                    </Tfoot>
                </Table>
            </div>
        </>
    );
}
