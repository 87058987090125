import { useEffect, useState } from "react";
import { useApiClient } from '../Contexts/ApiClientContext';
import { Loading, PageHeader } from 'Components';
import RateChart from "Components/MontaRateInfo/RateChart";
import { RateInfo } from "types/monta-rates";


export default function MontaRateInfo() {

    const apiClient = useApiClient();
    const [rateInfos, setRateInfos] = useState(null as RateInfo[] | null)

    useEffect(() => {
        const fetchRateInfos = async () => {
            try {
                const { data } = await apiClient.getRateInfos();
                if (data) {
                    setRateInfos(data);
                }
            } catch (error) {
                console.error("Error fetching rateInfos:", error);
            }
        };

        fetchRateInfos();
    }, [apiClient]);

    if (rateInfos === null) return (
        <Loading />
    )

    return (
        <>
            <PageHeader
                title="Monta Rate Info"
                subTitle="Rate Information from Monta"
            />

            <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                <RateChart rateInfo={rateInfos} />
            </div>
        </>
    );
}
