import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { RiLogoutBoxLine, RiSpeedUpFill } from "react-icons/ri";
import { MdMenuOpen } from "react-icons/md";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { getModulesForUser } from "modules";
import { FaHome } from "react-icons/fa";
import { Tooltip } from "@material-tailwind/react";
import { usePermissions } from "Contexts";


function Sidebar() {
    const [open, setOpen] = useState(false)
    const [animationEnd, setAnimationEnd] = useState(false)

    useEffect(() => {
        setAnimationEnd(false);
        if (!open) return;
        setTimeout(() => {
            setAnimationEnd(true);
        }, 300)
    }, [open])

    const { logout, isLoading } = useAuth0()
    const { userPermissions, isAllowed, Permissions } = usePermissions()
    const modules = getModulesForUser(userPermissions)

    const baseNavLinkClass = 'flex items-center p-2 rounded-lg group'
    const defaultNavLinkClass = baseNavLinkClass + ' text-gray-700 hover:bg-gray-100 transition-color hover:text-gray-900';
    const activeNavLinkClass = baseNavLinkClass + ' text-gray-700 bg-[#e7e9ea]';

    modules.unshift({
        name: 'Home',
        link: '/',
        icon: FaHome
    })

    return (
        <aside
            id="sidebar-multi-level-sidebar"
            className={classNames("sticky top-0 left-0 z-40 h-full transition-all duration-300 bg-gray-100 w-16 border-r border-[#d6d8d9]", {
                "!w-72": open
            })}
            aria-label="Sidebar">
            <div className="flex flex-col justify-between h-full px-3 py-3 overflow-y-auto">
                <div>
                    <ul className="space-y-2 font-medium">
                        {modules.map((module) => (
                            <li key={module.link}>
                                <Tooltip content={module.name} placement="right" className={classNames("z-50", open ? 'hidden' : '')}>
                                    <NavLink to={module.link} className={({ isActive }) =>
                                        isActive ? activeNavLinkClass : defaultNavLinkClass
                                    }>
                                        <module.icon className="shrink-0 h-6 w-6 transition-all" />
                                        {open && (
                                            <span className={classNames("ms-3 whitespace-nowrap opacity-0", {
                                                'opacity-100': animationEnd
                                            })}>
                                                {module.name}
                                            </span>
                                        )}
                                    </NavLink>
                                </Tooltip>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* --- Bottom links --- */}
                <div>
                    {open && (
                        <>
                            <ul>
                                {isAllowed(Permissions.READ_RATE_LIMITS) && (
                                    <li className="mt-auto">
                                        <Tooltip content="Monta Rate Info" placement="right" className={open ? 'hidden' : ''}>
                                            <NavLink
                                                to="/monta-rate-info"
                                                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group w-full"
                                            >
                                                <RiSpeedUpFill className="flex-shrink-0 w-6 h-6 text-gray-600 transition duration-75 group-hover:text-gray-900" />
                                                {open && (
                                                    <span className={classNames("ms-3 whitespace-nowrap opacity-0", {
                                                        'opacity-100': animationEnd
                                                    })}>
                                                        Monta Rate Info
                                                    </span>
                                                )}
                                            </NavLink>
                                        </Tooltip>
                                    </li>
                                )}
                                <li className="mt-auto">
                                    <Tooltip content="Log out" placement="right" className={open ? 'hidden' : ''}>
                                        <button
                                            disabled={isLoading}
                                            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                                            className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group w-full"
                                        >
                                            <RiLogoutBoxLine className="flex-shrink-0 w-6 h-6 text-gray-600 transition duration-75 group-hover:text-gray-900" />
                                            {open && (
                                                <span className={classNames("ms-3 whitespace-nowrap opacity-0", {
                                                    'opacity-100': animationEnd
                                                })}>
                                                    Log out
                                                </span>
                                            )}
                                        </button>
                                    </Tooltip>
                                </li>
                            </ul>
                        </>
                    )}
                    <div className="flex gap-2 items-center justify-end">
                        {open ? (
                            <>
                                <button
                                    type="button"
                                    onClick={() => setOpen(false)}
                                    className="inline-flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 focus:outline-none"
                                >
                                    <MdMenuOpen className="cursor-pointer w-6 h-6" />
                                </button>
                            </>
                        ) : (
                            <button
                                type="button"
                                onClick={() => setOpen(true)}
                                className="rotate-180 inline-flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 focus:outline-none"
                            >
                                <MdMenuOpen className="cursor-pointer w-6 h-6" />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </aside >
    );
}

export default Sidebar;
