import classNames from 'classnames';
import { Button } from 'Components';
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";


type Props = {
    currentPage: number;
    pageSize: number;
    totalItems: number;
    handleNextPageClick: () => void;
    handlePreviousPageClick: () => void;
    alignment?: 'left' | 'center' | 'right';
}

const Pager = ({ currentPage, pageSize, totalItems, handleNextPageClick, handlePreviousPageClick, alignment = 'center' }: Props) => {
    const hasNextPage = currentPage * pageSize < totalItems;
    return (
        <div className={classNames("flex gap-2 items-center my-4", {
            'justify-center': alignment === 'center',
            'justify-end': alignment === 'right',
        })}>
            <Button
                disabled={currentPage <= 1}
                onClick={handlePreviousPageClick}
                size='small'
                className={classNames({
                    'invisible': currentPage <= 1
                })}
            >
                <IoMdArrowRoundBack className="w-3.5 h-3.5 me-2" />
                Prev
            </Button>
            <div className="text-sm text-gray-700" title={`Total entries: ${totalItems}`}>
                <span className="font-semibold text-gray-900">{currentPage}</span> of <span className="font-semibold text-gray-900">{Math.ceil(totalItems / pageSize)}</span>
            </div>
            <Button
                disabled={!hasNextPage}
                onClick={handleNextPageClick}
                size='small'
                className={classNames({
                    'invisible': !hasNextPage
                })}
            >
                Next
                <IoMdArrowRoundForward className="w-3.5 h-3.5 ms-2" />
            </Button>
        </div>
    )
}

export default Pager;
