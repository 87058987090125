import { Link } from "react-router-dom";
import { getModulesForUser } from 'modules';
import { usePermissions } from "Contexts";

const ModuleGrid = () => {
    const { userPermissions } = usePermissions();
    const modules = getModulesForUser(userPermissions)
    return (
        <div className="flex flex-wrap gap-8 mt-8">
            {modules.map((module) => (
                <Link key={module.link} to={module.link} className="flex flex-col items-center justify-center border rounded-lg px-4 py-8 border-gray-500 shadow-md bg-white min-w-60 hover:shadow-lg transition-all hover:scale-105 group">
                    {module.icon && <module.icon className="text-6xl text-gray-600 group-hover:text-gray-900 transition-all" />}
                    <h3 className="text-xl font-semibold">{module.name}</h3>
                </Link>
            ))}
        </div>
    )
}

export default ModuleGrid;
