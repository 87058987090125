import { useEffect, useState } from 'react';
import { useApiClient } from 'Contexts/ApiClientContext';
import { RequisitionStock } from 'types/stock';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Loading } from 'Components';

type Props = {
    montaCode: string
    className?: string
}

const AutomaticStockToPurchase = ({ montaCode, className }: Props) => {
    const [automaticStockToPurchase, setAutomaticStockToPurchase] = useState<RequisitionStock[] | null>(null);

    const apiClient = useApiClient();

    useEffect(() => {
        const fetchAutomaticStockToPurchase = async () => {
            try {
                const { data } = await apiClient.getAutomaticRequisitionStocksBySupplier(montaCode ?? '');
                if (data) {
                    setAutomaticStockToPurchase(data);
                }
            } catch (error) {
                console.error("Error fetching suppliers:", error);
                setAutomaticStockToPurchase([]);
            }
        };

        fetchAutomaticStockToPurchase();
    }, [apiClient, montaCode]);

    if (automaticStockToPurchase === null) return (
        <Loading />
    );

    if (automaticStockToPurchase.length === 0) return (
        <div>No automatic stock to purchase.</div>
    );

    const totalPrice = automaticStockToPurchase.reduce((acc, stock) => acc + stock.quantity * stock.stock.price.purchasePrice.value, 0);

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>Product name</Th>
                    <Th>Sku</Th>
                    <Th>Ean</Th>
                    <Th className="text-right">Available</Th>
                    <Th className="text-right">Minimum</Th>
                    <Th className="text-right">Reserved</Th>
                    <Th className="text-right">Forecasted</Th>
                    <Th className="text-right">Warehouse</Th>
                    <Th className="text-right">Step</Th>
                    <Th className="text-right">To purchase</Th>
                </Tr>
            </Thead>
            <Tbody>
                {automaticStockToPurchase?.map(requisition => (
                    <Tr key={requisition.product.sku}>
                        <Td>{requisition.product.name}</Td>
                        <Td>{requisition.product.sku}</Td>
                        <Td>{requisition.product.ean}</Td>
                        <Td className="text-right">{requisition.stock.amounts.available}</Td>
                        <Td className="text-right">{requisition.stock.amounts.minimum}</Td>
                        <Td className="text-right">{requisition.stock.amounts.reserved}</Td>
                        <Td className="text-right">{requisition.stock.amounts.forecasted}</Td>
                        <Td className="text-right">{requisition.stock.amounts.warehouse}</Td>
                        <Td className="text-right">{requisition.stock.amounts.step}</Td>
                        <Td className="text-right">{requisition.quantity}</Td>
                    </Tr>
                ))}
            </Tbody>
            <Tfoot>
                <Tr>
                    <Td colSpan={10} className="text-right">Total cost: €&thinsp;{totalPrice.toFixed(2)}</Td>
                </Tr>
            </Tfoot>
        </Table>
    )
}

export default AutomaticStockToPurchase;
