import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";

interface PageContextProps {
  title: string;
  setTitle: (title: string) => void;
}

export const DEFAULT_PAGE_TITLE = "PROFORTO App";

const PageContext = createContext<PageContextProps | undefined>(undefined);

export const PageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [title, setTitle] = useState(DEFAULT_PAGE_TITLE);
  const { origin } = window.location;

  useEffect(() => {
    let modifiedTitle = title;
    if (!['https://procurement.proforto.app', 'https://proforto.app'].includes(origin)) {
      modifiedTitle = `🚧${title}`
    }

    document.title = modifiedTitle;
  }, [origin, title]);

  return (
    <PageContext.Provider value={{ title, setTitle }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = (): PageContextProps => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error("usePageContext must be used within a PageProvider");
  }
  return context;
};
