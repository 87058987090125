import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { Header, Sidebar } from 'Components';

const Layout = () => {
    const {
        isAuthenticated,
      } = useAuth0();

    return (
        <div className='min-h-screen'>
            <Header />
            <div className="flex flex-row relative pt-[68px] h-screen overflow-y-scroll">
                {isAuthenticated && <Sidebar />}
                <main className="flex flex-col flex-1 justify-between max-w-[1800px] mx-auto">
                    <div className="p-6">
                        {<Outlet />}
                    </div>
                    <div className="text-center justify-self-end p-6 text-sm text-gray-400 italic">
                        Proforto App v{window.PROCUREMENT_APP_VERSION}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Layout
